import React from 'react'

import { Button, type ButtonProps, Calendar as ReactAriaCalendar, Heading, useLocale } from 'react-aria-components'

import calendarStyles from './Calendar.module.less'
import { CalendarGrid } from './CalendarGrid'
import { UntilFurtherNoticeButton } from './UntilFurtherNoticeButton'

type CalendarGridProps = React.ComponentProps<typeof CalendarGrid>

export function Calendar(props: {
  showWeekNumbers?: boolean
  allowEmpty?: boolean
  visualSelection?: CalendarGridProps['visualSelection']
}): JSX.Element {
  const { locale } = useLocale()
  const showWeekNumbers = props.showWeekNumbers ?? locale === 'sv-SE'

  return (
    <ReactAriaCalendar>
      <header className={calendarStyles.Calendar_Header}>
        <RoundButton slot="previous" data-testid="prev-button">
          <span className="fas fa-chevron-left" />
        </RoundButton>
        <Heading />
        <RoundButton slot="next" data-testid="next-button">
          <span className="fas fa-chevron-right" />
        </RoundButton>
      </header>
      <CalendarGrid visualSelection={props.visualSelection} showWeekNumbers={showWeekNumbers} />
      <div className={calendarStyles.Calendar_Footer}>{props.allowEmpty && <UntilFurtherNoticeButton />}</div>
    </ReactAriaCalendar>
  )
}

function RoundButton(props: ButtonProps): JSX.Element {
  return <Button {...props} className={calendarStyles.Calendar_RoundButton} />
}
