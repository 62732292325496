import Datetime from 'utils/datetime/datetime'
import { DeepReadonly } from 'utils/types'
import { proxy, snapshot } from 'valtio'

import { shouldShowCustomDatepicker } from '../utils/useShouldShowCustomDatepicker'

import { parseZoomResolution, ZoomResolution } from './zoomResolutionSchema'

const CACHE_KEY_SHOW_OUTCOME_CHECKBOX = `optimizeShowOutcomeCheckbox`
const CACHE_KEY_ZOOM_RESOLUTION_SWITCH = `optimizeZoomResolutionSwitch`

export type OptimizeViewStore = {
  activeSystemId?: System['id']

  showOutcomeCheckbox: boolean
  zoomResolutionSwitch: ZoomResolution
  currentOptimizationHour: ISODateTime
  optimizedViewRange: [ISODateTime, ISODateTime]
  productionPlanStartTime: ISODateTime
  measEndTime: ISODateTime

  updateCurrentOptimizationHour(): void
  updateOptimizedViewRange(): void
}

function initializeOptimizeViewStore(system?: DeepReadonly<System> | null): OptimizeViewStore {
  const shouldHaveCustomDatepicker = system ? shouldShowCustomDatepicker(system) : false
  const defaultZoomResolutionSwitch = shouldHaveCustomDatepicker
    ? ({ type: 'custom', startTime: Datetime.getISONow(0), endTime: Datetime.getISONow(169) } as const)
    : ({ type: 'week' } as const)

  const initialOutcomeCheckbox = localStorage.getItem(CACHE_KEY_SHOW_OUTCOME_CHECKBOX) !== `false`
  let showOutcomeCheckbox = initialOutcomeCheckbox

  const storedZoomResolutionSwitch = localStorage.getItem(CACHE_KEY_ZOOM_RESOLUTION_SWITCH)
  const initialZoomResolutionSwitch = parseZoomResolution(storedZoomResolutionSwitch, defaultZoomResolutionSwitch, {
    allowCustom: shouldHaveCustomDatepicker,
  })

  let zoomResolutionSwitch: ZoomResolution = initialZoomResolutionSwitch

  let optimizedViewRange: [ISODateTime, ISODateTime] = optimizeViewRange(showOutcomeCheckbox, zoomResolutionSwitch)
  let currentOptimizationHour: ISODateTime = Datetime.getCurrentOptimizationHour()

  return {
    activeSystemId: system?.id,

    get showOutcomeCheckbox() {
      return showOutcomeCheckbox
    },
    set showOutcomeCheckbox(value: boolean) {
      localStorage.setItem(CACHE_KEY_SHOW_OUTCOME_CHECKBOX, value ? `true` : `false`)
      showOutcomeCheckbox = value

      this.updateOptimizedViewRange()
    },

    get zoomResolutionSwitch() {
      return zoomResolutionSwitch
    },
    set zoomResolutionSwitch(value: ZoomResolution) {
      localStorage.setItem(CACHE_KEY_ZOOM_RESOLUTION_SWITCH, JSON.stringify(value))
      zoomResolutionSwitch = value

      this.updateOptimizedViewRange()
    },

    get currentOptimizationHour() {
      return currentOptimizationHour
    },
    set currentOptimizationHour(value: ISODateTime) {
      currentOptimizationHour = value
    },
    updateCurrentOptimizationHour() {
      this.currentOptimizationHour = Datetime.getCurrentOptimizationHour()
    },

    get optimizedViewRange() {
      return optimizedViewRange
    },
    set optimizedViewRange(value: [ISODateTime, ISODateTime]) {
      optimizedViewRange = value
    },
    updateOptimizedViewRange() {
      this.optimizedViewRange = optimizeViewRange(this.showOutcomeCheckbox, this.zoomResolutionSwitch)
    },

    get productionPlanStartTime() {
      const [datasetStartTime] = this.optimizedViewRange
      return Datetime.isBeforeOrEqual(this.currentOptimizationHour, datasetStartTime) ? datasetStartTime : this.currentOptimizationHour
    },
    get measEndTime() {
      const [, datasetEndTime] = this.optimizedViewRange
      return Datetime.isAfterOrEqual(this.currentOptimizationHour, datasetEndTime) ? datasetEndTime : this.currentOptimizationHour
    },
  }
}

const optimizeViewStore = proxy<OptimizeViewStore>(initializeOptimizeViewStore())

export function resetOptimizeViewStore(system?: DeepReadonly<System> | null): void {
  Object.assign(optimizeViewStore, initializeOptimizeViewStore(system))
}

export function optimizeViewRange(
  includeOutcome?: boolean,
  zoomResolution?: OptimizeViewStore['zoomResolutionSwitch']
): [ISODateTime, ISODateTime] {
  if (includeOutcome === undefined || zoomResolution === undefined) {
    const snap = snapshot(optimizeViewStore)
    includeOutcome = snap.showOutcomeCheckbox
    zoomResolution = snap.zoomResolutionSwitch
  }

  if (zoomResolution.type === `day` && !includeOutcome) {
    return [Datetime.getISONow(0), Datetime.getISONow(24)]
  }
  if (zoomResolution.type === `day` && includeOutcome) {
    return [Datetime.getISONow(-23), Datetime.getISONow(24)]
  }
  if (zoomResolution.type === `week` && !includeOutcome) {
    return [Datetime.getISONow(0), Datetime.getISONow(169)]
  }
  if (zoomResolution.type === `week` && includeOutcome) {
    return [Datetime.getISONow(-167), Datetime.getISONow(169)]
  }

  if (zoomResolution.type === 'custom') {
    return [zoomResolution.startTime, zoomResolution.endTime]
  }

  return [Datetime.getISONow(1), Datetime.getISONow(169)]
}

export default optimizeViewStore
