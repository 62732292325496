import React, { ReactElement } from 'react'

import { Button, Grid, Icon } from '@mui/material'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './ErrorFallback.module.less'

type ErrorFallbackProps = {
  error: Error
  resetErrorBoundary: () => void
}

export default function ErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps): ReactElement {
  const { t } = useTranslation()

  return (
    <div role="alert">
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid item>
          <Icon className={classNames(`fal fa-exclamation-triangle`, styles.ErrorFallback_Icon)} />
          <span>{t(`Content failed to load`)}</span>
        </Grid>
        {process.env.NODE_ENV !== `production` ? <pre>{error.message}</pre> : null}
        <Button onClick={resetErrorBoundary}>{t(`Try again`)}</Button>
      </Grid>
    </div>
  )
}
