import uiConfigStore from 'store/uiConfig/uiConfig'

import { addOneZeroValueToEndOfDataset, fillPeriodWithFirstIndexValue, limitDataset, setNanIfZero } from 'helpers/dataset.helper/dataset.helper'
import { clone } from 'helpers/global.helper/global.helper'

export function postProcessDatasets(datasets: Dataset[], datasetInstruction: DatasetInstruction): Dataset[] {
  // Dummy dataset instruction if none is provided that will apply no filters
  if (!datasetInstruction) {
    datasetInstruction = {
      filter: {},
    }
  }

  const processedDatasets: Dataset[] = []

  datasets.forEach((dataset) => {
    let values = clone(dataset.values)
    const times = clone(dataset.times)

    if (datasetInstruction.filter?.limit_max !== undefined || datasetInstruction.filter?.limit_min !== undefined) {
      values = limitDataset({ values }, datasetInstruction.filter).values
    }

    if (datasetInstruction.filter?.zero_is_nan) {
      values = setNanIfZero({ values }, datasetInstruction.filter).values
    }

    if (datasetInstruction.filter?.mark_hours_ahead) {
      uiConfigStore.markHoursAhead = true
    } else {
      uiConfigStore.markHoursAhead = false
    }

    if (datasetInstruction.filter?.add_one_zero_value_to_end_of_dataset) {
      values = addOneZeroValueToEndOfDataset({ values }).values
    }

    if (datasetInstruction.filter?.show_as_negative) {
      values = values.map((v) => (v !== null ? (v = -Math.abs(v)) : v))
    }

    let processedDataset = {
      ...dataset,
      values,
      times,
    }

    if (datasetInstruction.filter?.fill_period_with_first_index_value) {
      processedDataset = fillPeriodWithFirstIndexValue(datasetInstruction, processedDataset)
    }

    processedDatasets.push(processedDataset)
  })

  return processedDatasets
}