import { resetFilterTags } from 'store/filter/filter'
import { resetZoomOnAllRoutes } from 'store/zoom/zoom'
import { proxy } from 'valtio'
import { subscribeKey } from 'valtio/utils'

type AuthStore = {
  loginTimestamp: number
  user: User | null
  activeSystem: System | null
  isBeta: boolean
  systemId: number | null
  permissionsAndDescriptions: {
    permission: UserPermission,
    description: string
  }[]
  permissions: Set<UserPermission>
  optModelId?: number
  organizationId: number
  isSuperUser: boolean
}

let globalCurrency: Currency = 'SEK'

export const ACTIVE_SYSTEM_LOCAL_STORAGE_KEY = `currentActiveSystem`
export function getActiveSystemFromLocalStorage(): System | null {
  const userItem = localStorage.getItem(`user`)
  const activeSystemItem = localStorage.getItem(ACTIVE_SYSTEM_LOCAL_STORAGE_KEY)

  if (!userItem) {
    if (activeSystemItem) {
      localStorage.removeItem(ACTIVE_SYSTEM_LOCAL_STORAGE_KEY)
    }
    return null
  }

  const activeSystem: System | null = activeSystemItem ? JSON.parse(activeSystemItem) : null
  globalCurrency = activeSystem?.currency || 'SEK'
  return activeSystem
}

const authStore = proxy<AuthStore>(
  {
    loginTimestamp: +new Date(),
    user: null,
    activeSystem: getActiveSystemFromLocalStorage(),
    isBeta: URLSearchParams ? !!new URLSearchParams(window.location?.search)?.get(`beta`) : false,
    get systemId() {
      return this.activeSystem?.id ?? null
    },
    get permissions() {
      return new Set<UserPermission>(
        (this.user?.system_permissions || [])
          .filter((permission) => permission.system === this.activeSystem?.id)
          .map((permission) => permission.permission)
      )
    },
    get permissionsAndDescriptions() {
      return (this.user?.system_permissions || [])
        .filter((permission) => permission.system === this.activeSystem?.id)
        .map((permission) => {
          return { permission: permission.permission, description: permission.description }
        })
    },
    get optModelId() {
      return this.activeSystem?.primary_opt_model?.id
    },
    get organizationId() {
      return this.activeSystem?.organization || 0
    },
    get isSuperUser() {
      return this.user?.is_superuser === true
    },
  }
)

subscribeKey(authStore, 'activeSystem', (activeSystem) => {
  resetZoomOnAllRoutes()
  resetFilterTags()
  globalCurrency = activeSystem?.currency || 'SEK'
})

export function getCurrency(): Currency {
  // NOTE: Global currency is a global variable (in this file) instead of reading from snapshot because tests fail otherwise. This is because auth store is not initialized (because of other mocks) and therefore undefined in tests.
  return globalCurrency
}

export function setCurrency(currency: Currency): void {
  globalCurrency = currency
}

export default authStore
