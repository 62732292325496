import { proxy, snapshot } from 'valtio'

type QueueStore = {
  originalQueueLength: number
  successfulMessages: number
  errorMessages: number
  successPercentage: number
  errorPercentage: number
  showQueue: boolean
}

const queueStore = proxy<QueueStore>(
  {
    originalQueueLength: 0,
    successfulMessages: 0,
    errorMessages: 0,
    get showQueue() {
      return this.originalQueueLength > 0
    },
    get successPercentage() {
      return this.originalQueueLength > 0 ? Math.round((this.successfulMessages / this.originalQueueLength) * 100) : 0
    } ,
    get errorPercentage() {
      return this.originalQueueLength > 0 ? Math.round((this.errorMessages / this.originalQueueLength) * 100) : 0
    },
  }
)

export function incrementTotalQueueLength(): void {
  const queueSnap = snapshot(queueStore)
  queueStore.originalQueueLength = queueSnap.originalQueueLength + 1
}

export function incrementQueueSuccess(): void {
  const queueSnap = snapshot(queueStore)
  queueStore.successfulMessages = queueSnap.successfulMessages + 1
}

export function incrementQueueError(): void {
  const queueSnap = snapshot(queueStore)
  queueStore.errorMessages = queueSnap.errorMessages + 1
}

export function resetQueue(): void {
  queueStore.originalQueueLength = 0
  queueStore.successfulMessages = 0
  queueStore.errorMessages = 0
}

export default queueStore
