import { apiClient } from 'api/apiClient/apiClient'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryOptions } from 'react-query'
import authStore from 'store/auth/auth'
import { useAlert } from 'ui/components/AlertContext/AlertContext'
import { useAuth } from 'ui/components/AuthContext/AuthContext'

import { isEqual } from 'helpers/global.helper/global.helper'

export const SYSTEM_QUERY_KEY = `system`
export const SYSTEMS_QUERY_KEY = `systems`

export type SystemUpdateDataRequest = {
  primary_digital_twin_id: number | undefined
}

export function useSystems() {
  const { user, activeSystem, setActiveSystem } = useAuth()

  return useQuery(SYSTEMS_QUERY_KEY, () =>
    apiClient<System[]>(`systems`).then((results) => {

      if (results.length > 0) {
        if (!activeSystem) {
          // No active system set, set it to the user's default.
          const system = results.find((system) => system.id === user?.system) ?? results[0]
          setActiveSystem(system)
          authStore.activeSystem = system
        } else {
          // Update the active system to make sure localStorage has the most recent version.
          const system = results.find((system) => system.id === activeSystem.id) ?? results[0]
          if ((!isEqual(system, activeSystem))) {
            setActiveSystem(system)
            authStore.activeSystem = system
          }
        }
      }

      return results
    })
  )
}

export function useSystem({ id, queryConfig }: { id?: number; queryConfig?: UseQueryOptions<System, unknown> }) {
  const queryClient = useQueryClient()
  return useQuery<System>([SYSTEM_QUERY_KEY, id], () => apiClient<System>(`systems/${id}`), {
    initialData: () => {
      const state = queryClient.getQueryState<System[], Error>(SYSTEMS_QUERY_KEY)

      if (state?.data && id !== null && Date.now() - state.dataUpdatedAt <= 60 * 1000) {
        return state.data.find((d) => d.id === id)
      }
    },
    ...queryConfig,
    enabled: !!id && (queryConfig?.enabled !== undefined ? queryConfig.enabled : true),
  })
}

export function useUpdateSystem(): UseMutationResult<System, unknown, { id: number; data: SystemUpdateDataRequest; }, unknown> {
  const { t } = useTranslation()
  const { success: successAlert, error: errorAlert } = useAlert()
  const { setActiveSystem } = useAuth()
  return useMutation(({ id, data }: { id: number, data: SystemUpdateDataRequest }) => apiClient<System>(`systems/${id}`, { method:'PATCH', data }),
    {
      onSuccess: (data) => {
        if (data) {
          setActiveSystem(data)
          authStore.activeSystem = data
          successAlert(t('Update successfull'))
        }
      },
      onError: () => {
        errorAlert(t(`Update failed. Try again or contact us if the problem persists.`))
      },
    }
  )
}

export function useSystemAutomaticOptimizationToggleMutation() {
  const { t } = useTranslation()
  const { error: errorAlert } = useAlert()
  const queryClient = useQueryClient()
  return useMutation(
    ({ id }: { id: number }) =>
      apiClient(`systems/${id}/toggle_automatic_optimization`, {
        method: `PATCH`,
      }),
    {
      onMutate: async ({ id }: { id: number }) => {
        const key = [SYSTEM_QUERY_KEY, id]
        // Canceling any conflicting queries to not overwrite the optimistic update
        await queryClient.cancelQueries(key)

        // Saving previous system for rollback if error
        const previousSystem = queryClient.getQueryData<System | undefined>(key)

        // Optimistically change system
        queryClient.setQueryData<System | undefined>(key, (old) =>
          old ? { ...old, automatic_optimization: !old.automatic_optimization } : old
        )

        // Return rollback function
        return () => queryClient.setQueryData(key, previousSystem)
      },
      onError: (error, id, rollback) => {
        rollback?.()
        errorAlert(t(`Automatic optimization failed to activate! Try again or contact us if the problem persists.`))
      },
    }
  )
}

export function useUploadOpusFile() {
  const { success: successAlert, error: errorAlert } = useAlert()
  const { activeSystem } = useAuth()

  return useMutation(
    ({ file }: { file: File }) => {
      if (!activeSystem) {
        return Promise.reject(new Error('activeSystem is undefined'))
      }
      const formData = new FormData()
      formData.append('file', file)
      return apiClient(`systems/${activeSystem.id}/upload_opus_file`, {
        method: 'POST',
        body: formData,
      })
    },
    {
      onSuccess: () => {
        successAlert(i18n.t('Upload successfull'))
      },
      onError: () => {
        errorAlert(i18n.t(`Upload failed`))
      },
    }
  )
}