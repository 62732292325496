export type CommonColors = {
  black: React.CSSProperties[`color`]
  white: React.CSSProperties[`color`]
}

const common = {
  black: `#000`,
  white: `#fff`,
}

export default common
