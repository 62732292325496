import common from '../colors/common'
import primary from '../colors/primary'
import secondary from '../colors/secondary'

const MuiButtonStyles = {
  root: {
    fontWeight: `normal` as const,
    textTransform: `none` as const,
  },
  containedPrimary: {
    backgroundColor: primary.main,
    '&.Mui-disabled': {
      backgroundColor: primary.disabled,
      color: common.white,
    },
    '&:hover': {
      backgroundColor: primary.hover,
    },
    borderRadius: 100,
    color: primary.contrastText,
  },
  containedSecondary: {
    backgroundColor: secondary.main,
    '&.Mui-disabled': {
      backgroundColor: secondary.disabled,
    },
    '&:hover': {
      backgroundColor: secondary.hover,
    },
    borderRadius: 100,
    color: secondary.contrastText,
  },
}

export default MuiButtonStyles
