import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import sentry from 'utils/sentry/sentry'

// eslint-disable-next-line no-restricted-imports
import packageJson from '../../../package.json'

type BugsnagData = Record<string, string | number>

export default {
  isInitialized: false,
  init: function (): void {
    const apiKey = process.env.REACT_APP_BUGSNAG_API_KEY || ``
    const appVersion = packageJson.version
    const releaseStage = process.env.REACT_APP_STAGE || `development`

    if (this.isInitialized || !apiKey) {
      return
    }
    this.isInitialized = true

    Bugsnag.start({
      apiKey,
      appVersion,
      releaseStage,
      appType: `client`,
      plugins: [new BugsnagPluginReact()],
      enabledReleaseStages: [`production`, `staging`],
      onError: (event) => {
        event.addMetadata(`custom-data`, this.getUserData())
      },
    })
  },
  getUserData(): { userId: number; organizationId: number; systemId: number } {
    const userData = JSON.parse(localStorage.getItem(`user`) || `{}`)
    const userId = Number(userData?.id ?? -1)
    const organizationId = Number(userData?.organization || -1)
    const systemData = JSON.parse(localStorage.getItem(`currentActiveSystem`) || `{}`)
    const systemId = Number(systemData?.id || -1)

    return { userId, organizationId, systemId }
  },
  notify: function (err: Error, data: BugsnagData = {}): void {
    console.log(`Bugsnag notify error:`, err, data)

    sentry.captureException(err, data)

    if (!this.isInitialized) {
      return
    }

    Bugsnag.notify(err, (ev) => {
      ev.addMetadata(`custom-error-data`, data)
    })
  },
}
