/* eslint-disable @typescript-eslint/no-explicit-any */

import { incrementQueueError, incrementQueueSuccess, incrementTotalQueueLength, resetQueue } from 'store/queue/queue'

// Queue structure from DbS's queue.ts originally, however modified to only use one queue as we fetch data individually per uiconfig and to use queue store to update loading bar

const createTaskQueue = <T>(): TaskQueue<T> => {
  let tasks: T[] = []

  return {
    tasks,
    add: (task: T) => {
      tasks.push(task)
    },
    getNext: () => {
      return tasks.shift()
    },
    length: () => {
      return tasks.length
    },
    clear: () => {
      tasks = []
    },
  }
}

const taskQueue = createTaskQueue<() => void>()
let activeCount = 0
let concurrentQueueItems = parseInt(process.env.REACT_APP_CONCURRENT_QUEUE_ITEMS, 10)
if (isNaN(concurrentQueueItems) || concurrentQueueItems <= 0) {
  concurrentQueueItems = 2
}

type TaskFunction<T> = (...args: any[]) => Promise<T>

type TaskQueue<T> = {
  tasks: T[]
  add: (task: T) => void
  getNext: () => T | undefined
  length: () => number
  clear: () => void
}

export default function getQueue(): <T>(func: TaskFunction<T>, ...args: any[]) => Promise<T>{
  if (!Number.isInteger(concurrentQueueItems) || concurrentQueueItems <= 0) {
    throw new TypeError('Concurrency must be a positive integer')
  }

  function executeNextTask(): void {
    activeCount--

    if (taskQueue.length() > 0) {
      const task = taskQueue.getNext()
      if (task) {
        task()
      }
    } else {
      taskQueue.clear()
      resetQueue()
    }
  }

  async function runTask<T>(func: TaskFunction<T>, resolve: (value?: any) => void, args: any[]): Promise<void> {
    activeCount++
    const result = (async () => func(...args))()

    resolve(result)

    try {
      await result
      incrementQueueSuccess()
    } catch {
      incrementQueueError()
    }
    executeNextTask()
  }

  function enqueueTask<T>(func: TaskFunction<T>, resolve: (value?: any) => void, args: any[]): void {
    incrementTotalQueueLength() //Add to queue store to visualize the queue
    taskQueue.add(() => runTask(func, resolve, args))

    const initializeTask = async (): Promise<void> => {
      await Promise.resolve()

      if (activeCount < concurrentQueueItems && taskQueue.length() > 0) {
        const task = taskQueue.getNext()
        if (task) {
          task()
        }
      }
    }
    initializeTask()
  }

  const taskGenerator = <T>(func: TaskFunction<T>, ...args: any[]): Promise<T> => {
    return new Promise((resolve) => {
      enqueueTask(func, resolve, args)
    })
  }

  return taskGenerator
}
