import { Module } from 'api/modules/modules.api'
import { RoutesArray } from 'ui/components/Navigation/types'

export const filterRoutes = (routes: RoutesArray, modules: Module[]): RoutesArray => {
  const moduleNames = modules.map((item) => item.name)

  /**
   *  Filters routes, if only one child exists it should not be nested
   */
  const routesWithPossibleChildren: RoutesArray = []
  routes.forEach((item) => {
    let possibleChildren
    if (item.children) {
      possibleChildren = item.children.filter((child) => child.module && moduleNames.includes(child.module))
      if (possibleChildren.length === 1) {
        routesWithPossibleChildren.push({
          ...possibleChildren[0],
          sidebarName: possibleChildren[0].sidebarName,
        })
      } else if (possibleChildren.length > 0) {
        routesWithPossibleChildren.push(
          Object.assign({}, item, {
            children: possibleChildren,
          })
        )
      }
    } else {
      routesWithPossibleChildren.push(item)
    }
  })

  const filteredRoutes = routesWithPossibleChildren.filter((item) =>
    item.children || item.alwaysAvailable || (item.module && moduleNames.includes(item.module)) || (item.globalModule)
  )

  return filteredRoutes
}