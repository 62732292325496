import 'mdn-polyfills/Object.assign'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React, { ReactElement } from 'react'

import Bugsnag from '@bugsnag/js'
import './localization/localization.helper'
import { createRoot } from 'react-dom/client'
import { LoadingPlaceholderContainer } from 'ui/atoms'
import AllProviders from 'ui/components/AllProviders/AllProviders'
import schedulerInit from 'utils/scheduler/scheduler'
import sentry from 'utils/sentry/sentry'

import localizeInit from 'localization/localization'

import App from './ui/components/App/App'
import 'index.module.less'
import bugsnag from './utils/bugsnag/bugsnag'
import 'dev'
import 'index.css'

localizeInit()
bugsnag.init()
sentry.init()
schedulerInit()

const Root = (): ReactElement => {
  return (
    <AllProviders>
      <React.Suspense fallback={<LoadingPlaceholderContainer height="100%" />}>
        <App />
      </React.Suspense>
    </AllProviders>
  )
}

const RootWithErrorBoundary = (): ReactElement => {
  const ErrorBoundary = Bugsnag.getPlugin(`react`)?.createErrorBoundary(React)

  return ErrorBoundary ? (
    <ErrorBoundary>
      <Root />
    </ErrorBoundary>
  ) : (
    <Root />
  )
}

const container = document.getElementById(`root`)
if (!container) {
  throw new Error(`Container not found`)
}
const root = createRoot(container)

root.render(<RootWithErrorBoundary /> )

export default RootWithErrorBoundary
