import React, { ReactElement, Suspense } from 'react'

import { LoadingPlaceholderContainer } from 'ui/atoms'
import PrivateSwitch from 'ui/components/PrivateSwitch/PrivateSwitch'

import indexRoutes from 'routes/index.routes'

function UnauthenticatedApp(): ReactElement {
  return (
    <Suspense fallback={<LoadingPlaceholderContainer />}>
      <PrivateSwitch routes={indexRoutes} />
    </Suspense>
  )
}

export default UnauthenticatedApp
