import moment from 'moment'

export type ExportType = `PNG` | `CSV` | `PRINT`

export type ExportTimeData = {
  time: string
  [key: string]: number | string
}

export function getFileName(fileType: string): string {
  return `aurora_export_` + moment().format(`YYYY_MM_DD`) + `.` + fileType
}

export function getChosenDateFileName(fileType: string, currentDate: ISODateTime, title?: string): string {
  return title ? title + moment(currentDate).format(`YYYY_MM_DD`) + `.` + fileType : `aurora_` + moment(currentDate).format(`YYYY_MM_DD`) + `.` + fileType
}
