import { useMemo } from 'react'

import { apiClient } from 'api/apiClient/apiClient'
import { useQuery } from 'react-query'
import uiConfigStore from 'store/uiConfig/uiConfig'
import zoomStore from 'store/zoom/zoom'
import bugsnag from 'utils/bugsnag/bugsnag'
import Datetime from 'utils/datetime/datetime'
import { fetchDatasetsFromDbS } from 'utils/dbs/dbs'
import getQueue from 'utils/queue/queue'
import sentry from 'utils/sentry/sentry'
import { snapshot, useSnapshot } from 'valtio'

import {
  calcDataset,
  getCalcDatasetInstructionOrder,
  fillMissingHours,
  mergeDatasetsWithSameReturnId,
  getEarliestStartTime,
  getLatestStartTime,
  fillPeriodWithFirstIndexValue,
  setSmallValuesToZero,
  applyZoomToDatasets,
  calculateDatasetsWithSameReturnId,
} from 'helpers/dataset.helper/dataset.helper'
import { clone } from 'helpers/global.helper/global.helper'
import { queryClient } from 'helpers/queryClient'
import optimizeViewStore from 'views/OptimizeView/store/optimizeViewStore'

import { postProcessDatasets } from './dataset.api.helper'

export function datasetResToDatasets(datasetRes: DatasetResponse, datasetInstruction: DatasetInstruction): Dataset[] {
  // Dummy dataset instruction if none is provided that will apply no filters
  if (!datasetInstruction) {
    datasetInstruction = {
      filter: {},
    }
  }

  const times = datasetRes.data.map((d) => Datetime.toISOString(d.time))
  const returnIdToValues = datasetRes.data.reduce(
    (acc, d) => {
      Object.entries(d)
        .filter(([k]) => k !== `time`)
        .forEach(([returnId, value]) => {
          if (!acc[returnId]) {
            acc[returnId] = []
          }

          acc[returnId].push(value as number | null)
        })

      return acc
    },
    {} as Record<string, (number | null)[]>
  )

  const datasets: Dataset[] = Object.entries(returnIdToValues).map(([return_id, values]) => {
    return {
      return_id,
      times: times.slice(0, values.length),
      values,
      status: datasetRes.status,
      error_msg: datasetRes.error_msg,
    }
  })

  return postProcessDatasets(datasets, datasetInstruction)
}

export async function getDatasets(
  id: number,
  version: number,
  _datasetInstructions: DatasetInstruction[],
  datasetStartTime?: ISODateTime,
  datasetEndTime?: ISODateTime,
  options: {
    useQueue?: boolean
    fillMissingHourTimestamps?: boolean
    overrideAlias?: UiConfigAliases
    onlyReturnIds?: string[]
    returnIdRegexFilter?: string
    datasetCreatedAt?: string
    datasetCreatedAtOperator?: DatasetCreatedAtOperators
    forceFillMissingHours?: boolean
  } = {
    useQueue: true,
    fillMissingHourTimestamps: true,
    overrideAlias: {},
    onlyReturnIds: [],
    returnIdRegexFilter: '.*',
  }
): Promise<Dataset[]> {
  if (!options?.overrideAlias) {
    options.overrideAlias = {}
  }

  if (!_datasetInstructions?.length) {
    return []
  }

  // Serialize dataset instructions
  let datasetInstructions = clone(_datasetInstructions)
  if (datasetStartTime) {
    datasetInstructions = datasetInstructions.map((instruction) => ({
      ...instruction,
      filter: {
        ...instruction.filter,
        offset_start_time: 0,
        start_time: Datetime.toISOString(datasetStartTime),
      },
    }))

    options.overrideAlias.start_time = Datetime.toISOString(datasetStartTime)
    options.overrideAlias.offset_start_time = 0
  }

  if (datasetEndTime) {
    datasetInstructions = datasetInstructions.map((instruction) => ({
      ...instruction,
      filter: {
        ...instruction.filter,
        offset_end_time: 0,
        end_time: Datetime.toISOString(datasetEndTime),
      },
    }))

    options.overrideAlias.end_time = Datetime.toISOString(datasetEndTime)
    options.overrideAlias.offset_end_time = 0
  }

  if (options.datasetCreatedAt) {
    datasetInstructions = datasetInstructions.map((instruction) => ({
      ...instruction,
      filter: {
        ...instruction.filter,
        created_at: options.datasetCreatedAt,
        created_at_operator: options.datasetCreatedAtOperator,
      },
    }))
  }

  let datasets: Dataset[] = []
  let datasetRes!: DatasetResponse
  const uiConfigSnap = snapshot(uiConfigStore)
  const uid = uiConfigSnap.idToUiConfig[id]?.uid
  const uiConfig = uiConfigSnap.getParsedUiConfig(uid, options.overrideAlias)

  // Fetch datasets from DbS
  datasets = await fetchDatasetsFromDbS(datasetInstructions, {
    onlyReturnIds: options.onlyReturnIds,
    returnIdRegexFilter: options.returnIdRegexFilter,
  })

  // Fetch datasets from Django
  const queue = getQueue()
  const djangoFetchedDatasets = (
    await Promise.all(
      datasetInstructions
        .filter(({ type }) => type !== `calc` && type !== 'dbs')
        .map(async (datasetInstruction) => {
          const errorDataset: Dataset = {
            return_id: datasetInstruction.return_id,
            values: [],
            times: [],
          }

          try {
            const backendDatasetInstruction = clone(datasetInstruction)
            if (backendDatasetInstruction.filter?.aggregate_in_frontend) {
              delete backendDatasetInstruction.filter.aggregate
            }

            if (backendDatasetInstruction.filter) {
              if (!backendDatasetInstruction.filter.start_time) {
                delete backendDatasetInstruction.filter.start_time
              }
              if (!backendDatasetInstruction.filter.end_time) {
                delete backendDatasetInstruction.filter.end_time
              }
            }
            if (options?.useQueue !== false) {
              return queue(async () => {
                try {
                  datasetRes = await apiClient<DatasetResponse>(`dataset/${datasetInstruction.type}`, {
                    method: `POST`,
                    data: backendDatasetInstruction,
                  })
                  return datasetResToDatasets(datasetRes, datasetInstruction)
                } catch (error: unknown) {
                  bugsnag.notify(error as Error)
                  sentry.captureException(error as Error)
                  errorDataset.status = `error`
                  errorDataset.error_msg = (error as Error)?.message ?? ``
                  return [errorDataset]
                }
              })
            } else {
              datasetRes = await apiClient<DatasetResponse>(`dataset/${datasetInstruction.type}`, {
                method: `POST`,
                data: backendDatasetInstruction,
              })
              return datasetResToDatasets(datasetRes, datasetInstruction)
            }
          } catch (error: unknown) {
            bugsnag.notify(error as Error)
            sentry.captureException(error as Error)
            errorDataset.status = `error`
            errorDataset.error_msg = (error as Error)?.message ?? ``
          }

          return [errorDataset]
        })
    )
  ).flat()

  datasets.push(...djangoFetchedDatasets)

  // Post-process datasets & calculate derived datasets
  if (uiConfig?.props.operator_for_same_data_id) {
    // Calculate datasets with same return_id, depending on the operator
    datasets = calculateDatasetsWithSameReturnId(datasets, uiConfig?.props.operator_for_same_data_id as string)
  } else {
    // Merge datasets with same return_id
    datasets = mergeDatasetsWithSameReturnId(datasets)
  }

  // Fill missing hours
  if (options?.fillMissingHourTimestamps) {
    const earliestStartTime = getEarliestStartTime(datasetInstructions)
    const latestEndTime = getLatestStartTime(datasetInstructions)

    if (Datetime.isBefore(earliestStartTime, latestEndTime)) {
      datasets = fillMissingHours(datasets, earliestStartTime, latestEndTime, options?.forceFillMissingHours)
    } else {
      bugsnag.notify(
        new Error(
          `Earliest start time (${earliestStartTime}) is after latest end time (${latestEndTime}) for ui config ${id} version ${version}`
        )
      )
      sentry.captureException(
        new Error(
          `Earliest start time (${earliestStartTime}) is after latest end time (${latestEndTime}) for ui config ${id} version ${version}`
        )
      )
    }
  }

  return datasets
}

export function convertDatasetInstructionsToQueryKey(datasetInstrucitons: DatasetInstruction[]): DatasetInstruction[] {
  // Remove irrelevant data from dataset instruction that the server does not need. This will cache similar queries even if they are not identical.
  let di = clone(datasetInstrucitons)
  di = di.map((d) => {
    if (d?.filter?.aggregate_in_frontend) {
      d.filter.aggregate = ''
    }

    return d
  })

  return di
}

export function getSandboxProjectUiConfigRefreshToken(sandboxProjectId: number) {
  return `sandboxProjectUiConfigRefreshToken_${sandboxProjectId}`
}

export const DATASET_FOR_UI_CONFIG = 'datasetForUiConfig'
export const DATASET_FOR_UI_CONFIG_REFRESH_ON_OPTIMIZE = 'datasetForUiConfigRefreshOnOptimize'
export const DATASET_FOR_UI_CONFIG_REFRESH_ON_REAL_TIME_OPTIMIZE = 'datasetForUiConfigRefreshOnRealTimeOptimize'
export const DATASET_FOR_UI_CONFIG_REFRESH_FOLLOW_UP = 'datasetForUiConfigRefreshFollowUp'
export const DATASET_FOR_UI_CONFIG_REFRESH_ON_FUEL_PLAN = 'datasetForUiConfigRefreshOnFuelPlan'
export function useDatasets(
  id: number,
  version: number,
  _datasetInstructions: DatasetInstruction[],
  datasetStartTime?: ISODateTime,
  datasetEndTime?: ISODateTime,
  options: {
    ignoreZoom?: boolean
    uid?: number
    fillMissingHourTimestamps?: boolean
    useQueue?: boolean
    refreshOnOptimize?: boolean
    datasetRefreshToken?: string
    overrideAlias?: UiConfigAliases
    onlyReturnIds?: string[]
    returnIdRegexFilter?: string
    datasetCreatedAt?: string
    datasetCreatedAtOperator?: DatasetCreatedAtOperators
    forceFillMissingHours?: boolean
  } = {
    ignoreZoom: false,
    fillMissingHourTimestamps: true,
    datasetRefreshToken: DATASET_FOR_UI_CONFIG,
    overrideAlias: {},
    onlyReturnIds: [],
    returnIdRegexFilter: '.*',
  }
) {
  /*
  1. Fetch & cache API-response for datasets for entire period selected.
  2. Apply zoom-state for path route.
  3. Calc datasets after zoom-state.
*/

  const queryRefreshKey = options?.datasetRefreshToken || DATASET_FOR_UI_CONFIG
  const zoomSnap = useSnapshot(zoomStore)
  const zoomStartTime = zoomSnap.startTime
  const zoomEndTime = zoomSnap.endTime

  // Cached API-call
  const datasetResponse = useQuery(
    [
      queryRefreshKey,
      convertDatasetInstructionsToQueryKey(_datasetInstructions),
      datasetStartTime,
      datasetEndTime,
      options,
    ],
    async () => {
      const fillMissingHourTimestamps = options?.fillMissingHourTimestamps !== false
      const useQueue = options?.useQueue !== false

      return getDatasets(id, version, _datasetInstructions, datasetStartTime, datasetEndTime, {
        fillMissingHourTimestamps,
        useQueue,
        overrideAlias: options.overrideAlias,
        onlyReturnIds: options.onlyReturnIds,
        returnIdRegexFilter: options.returnIdRegexFilter,
        datasetCreatedAt: options.datasetCreatedAt,
        datasetCreatedAtOperator: options.datasetCreatedAtOperator,
        forceFillMissingHours: options.forceFillMissingHours,
      })
    }
  )

  //only calc datasets when there is data or if zoomstate has changed
  const calculatedDatasets = useMemo(() => {
    let datasets = datasetResponse.data ?? []
    if (datasetResponse.status !== 'success') {
      return []
    }

    datasets = options.ignoreZoom
      ? clone(datasets || [])
      : clone(applyZoomToDatasets(datasets || [], zoomStartTime, zoomEndTime))

    // Calculation datasets (based on fetched datasets)
    return calculateCalcDatasets(_datasetInstructions, datasets)
  }, [
    _datasetInstructions,
    datasetResponse.data,
    datasetResponse.status,
    options.ignoreZoom,
    zoomEndTime,
    zoomStartTime,
  ])

  // Update datasetResponse with new datasets (calc, zoom and aggregate)
  if (calculatedDatasets.length) {
    datasetResponse.data = calculatedDatasets
  }
  return datasetResponse
}

export function calculateCalcDatasets(datasetInstructions: DatasetInstruction[], datasets: Dataset[]): Dataset[] {
  const calcDatasetInstructions = getCalcDatasetInstructionOrder(datasetInstructions, datasets)

  calcDatasetInstructions.forEach((datasetInstruction) => {
    //TOOD: all these filter-functions also exists in function postProcessDatasets(), perhaps we should use the same functions for calc-datasets as well?

    let dataset = calcDataset(clone(datasets), datasetInstruction)

    if (datasetInstruction.filter.fill_period_with_first_index_value) {
      dataset = fillPeriodWithFirstIndexValue(datasetInstruction, dataset)
    }

    if (datasetInstruction.filter?.zero_is_nan) {
      const zerosAreNan = dataset.values.map((v) => (v === 0 ? null : v))
      dataset.values = zerosAreNan
    }

    if (datasetInstruction.filter?.show_as_negative) {
      const negativeValues = dataset.values.map((v) => (v !== null ? (v = -Math.abs(v)) : v))
      dataset.values = negativeValues
    }

    datasets.push(dataset)
  })

  datasets = setSmallValuesToZero(datasets, 0.0001)

  return datasets
}

export function updateOptimizeHourRange() {
  optimizeViewStore.updateCurrentOptimizationHour()
  optimizeViewStore.updateOptimizedViewRange()
}

export function reFetchAllDatasetsOnOptimize() {
  updateOptimizeHourRange()
  queryClient.invalidateQueries([DATASET_FOR_UI_CONFIG_REFRESH_ON_OPTIMIZE])
}

// TODO: We may have a case in the future or soon, that this has to be re-feteched every hour.
export function reFetchAllDatasetsOnFuelPlan() {
  queryClient.invalidateQueries([DATASET_FOR_UI_CONFIG_REFRESH_ON_FUEL_PLAN])
}

export const DATASET_INSTRUCTION = `datasetInstruction`
export function useDatasetInstructions(systemId: number, datasetTypes: string[], measDataForDisplay = false) {
  return useQuery([DATASET_INSTRUCTION, systemId, datasetTypes], async () => {
    if (datasetTypes.length === 0) {
      return []
    }
    const typesQuery = datasetTypes.map((type) => `${type}`).join(`&type=`)

    const data = apiClient<DatasetInstruction[]>('dataset/dataset_instructions', {
      method: 'GET',
      params: {
        system_id: systemId,
        meas_data_for_display: measDataForDisplay,
        type: typesQuery,
      },
    })

    if (!data) {
      return []
    }
    return data
  })
}
