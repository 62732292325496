import React from 'react'

import { RoutesArray } from 'ui/components/Navigation/types'

const LoginView = React.lazy(() => import('views/LoginView/LoginView'))

const indexRoutes: RoutesArray = [
  {
    path: `/pswdreset/:uid/:token`,
    component: LoginView,
    exact: true,
    contentComponent: `resetPassword`,
  },
  {
    path: `/accountverification/:email/:token`,
    component: LoginView,
    exact: true,
    contentComponent: `accountActivation`,
  },
  { path: `/`, component: LoginView },
]

export default indexRoutes
