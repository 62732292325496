import React, { PropsWithChildren, ReactElement } from 'react'

import Link from '@mui/material/Link'
import { Link as RouterLink, LinkProps } from 'react-router-dom'

type StyledLinkProps = {
  to: string
}

const MyLink = React.forwardRef<HTMLAnchorElement, LinkProps>(function MyLink(props, ref) {
  return <RouterLink {...props} innerRef={ref} />
})

export default function StyledLink({ children, ...rest }: PropsWithChildren<StyledLinkProps>): ReactElement {
  return (
    <Link underline="none" component={MyLink} {...rest}>
      {children}
    </Link>
  )
}
