import { proxy } from 'valtio'

type MFAAuthStore = {
  organisation_requires_mfa: boolean
  user_has_active_mfa: boolean
  ephemeral_token: string
  backup_codes?: string[]
}

const mfaAuthStore = proxy<MFAAuthStore>(
  {
    organisation_requires_mfa: true,
    user_has_active_mfa: false,
    ephemeral_token: '',
    backup_codes: [],
  }
)

export function setMFAAuth(
  organisation_requires_mfa: boolean,
  user_has_active_mfa: boolean,
  ephemeral_token: string, backup_codes?: string[]): void {

  mfaAuthStore.organisation_requires_mfa = organisation_requires_mfa
  mfaAuthStore.user_has_active_mfa = user_has_active_mfa
  mfaAuthStore.ephemeral_token = ephemeral_token
  mfaAuthStore.backup_codes = backup_codes
}

export default  mfaAuthStore