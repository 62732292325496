import React, { ReactElement } from 'react'

import { Hidden, Typography } from '@mui/material'

export default function StageBanner(): ReactElement | null {
  if (process.env.REACT_APP_STAGE === `production`) return null
  return (
    <Hidden smDown>
      <div
        style={{
          display: `flex`,
          position: `fixed`,
          left: `50%`,
          zIndex: 1202, // To position on top of header which has zIndex=1201
          height: 50,
          alignItems: `center`,
        }}
      >
        <Typography
          variant="body1"
          style={{
            backgroundColor: process.env.REACT_APP_STAGE === `staging` ? `#ffa500ed` : `#808080`,
            borderRadius: 5,
            color: `#fff`,
            padding: 10,
            paddingLeft: 40,
            paddingRight: 40,
            fontWeight: `bolder`,
          }}
        >
          {process.env.REACT_APP_STAGE === `staging` ? `STAGING` : `LOCAL DEV`}
        </Typography>
      </div>
    </Hidden>
  )
}
