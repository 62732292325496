import React, { ReactElement } from 'react'

import { Hidden, Divider, List } from '@mui/material'

import auroraLogo from '/img/abs_logo_white.png'

import { useLocation } from 'react-router'
import { RoutesArray } from 'ui/components/Navigation/types'

import SidebarItem from '../SidebarItem/SidebarItem'
import SubMenuItem from '../SubMenuItem/SubMenuItem'

import styles from './DrawerItems.module.less'

type DrawerItemsProps = {
  routes: RoutesArray
  drawerOpen: boolean
}

function DrawerItems({ routes, drawerOpen }: DrawerItemsProps): ReactElement {
  const location = useLocation()
  return (
    <>
      <div className={styles.DrawerItems_ToolBar}>
        <Hidden smUp>
          <img width="60%" src={auroraLogo} alt="Aurora by Sigholm" />
        </Hidden>
      </div>
      <Divider />
      <List>
        {routes.map((item, index) => {
          if (item.redirect || item.notInSidemenu) {
            return null
          }
          if (item.children) {
            if (item.children.length === 0) {
              return null
            }
            if (item.children.length === 1) {
              return item.children.map((child, i) => {
                return (
                  <SidebarItem
                    key={`${child.sidebarName ?? ''}-${index}-${i}`}
                    active={child.path === location.pathname}
                    item={child}
                    sideBarOpen={drawerOpen}
                  />
                )
              })
            } else {
              return (
                <SubMenuItem
                  item={item}
                  key={`${item.sidebarName ?? ''}-${index}`}
                  sideBarOpen={drawerOpen}
                  location={location}
                />
              )
            }
          } else {
            return (
              <SidebarItem
                key={`${item.sidebarName ?? ''}-${index}`}
                active={item.path === location.pathname}
                item={item}
                sideBarOpen={drawerOpen}
              />
            )
          }
        })}
      </List>
    </>
  )
}

export default React.memo(DrawerItems)
