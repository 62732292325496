const DEFAULT_DATASET_TEMPLATES: Record<string, DatasetInstruction> = {
  temp_forecast: {
    type: 'dbs',
    filter: {},
    contract: {
      global_read_api: true,
      id_renaming: {
        temperature: 'temp_forecast',
      },
      metadata_filter: {
        include: {
          internal_id: ['temperature'],
          organization: ['$weather_forecast_organization'],
          system: ['$weather_forecast_system'],
          abs_service: ['abs_weather'],
          percentile: ['$weather_forecast_percentile'],
        },
        exclude: {},
      },
    } satisfies DatasetContractDbS,
    return_id: '',
  },
  temp_outcome: {
    type: 'dbs',
    filter: {},
    contract: {
      global_read_api: true,
      id_renaming: {
        '$meas_temperature_tag': 'temp_outcome',
      },
      metadata_filter: {
        include: {
          internal_id: ['$meas_temperature_tag'],
          organization: ['$organization'],
          system: ['$system'],
          abs_service: ['abs_meas'],
        },
        exclude: {},
      },
    } satisfies DatasetContractDbS,
    return_id: '',
  },
  el_price_forecast: {
    type: 'dbs',
    filter: {},
    contract: {
      global_read_api: true,
      id_renaming: {
        '$electricity_price_internal_id': 'el_price_forecast',
      },
      metadata_filter: {
        include: {
          internal_id: ['$electricity_price_internal_id'],
          organization: ['global'],
          abs_service: ['abs_forecast'],
          dataset_type: ['forecast'],
        },
        exclude: {},
      },
    } satisfies DatasetContractDbS,
    return_id: '',
  },
  el_price_outcome: {
    type: 'dbs',
    filter: {},
    contract: {
      global_read_api: true,
      id_renaming: {
        '$electricity_price_internal_id': 'el_price_outcome',
      },
      metadata_filter: {
        include: {
          internal_id: ['$electricity_price_internal_id'],
          organization: ['global'],
          abs_service: ['abs_electricity_price'],
          dataset_type: ['measurement'],
          data_source: ['nordpool'],
          currency: ['$currency'],
        },
        exclude: {},
      },
    } satisfies DatasetContractDbS,
    return_id: '',
  },
  el_price: {
    type: 'dbs',
    filter: {},
    contract: {
      global_read_api: true,
      id_renaming: {
        '$electricity_price_internal_id': 'el_price',
      },
      metadata_filter: {
        include: {
          internal_id: ['$electricity_price_internal_id'],
          organization: ['global'],
          data_source: ['nordpool_abs'],
          dataset_type: ['calculation'],
        },
        exclude: {},
      },
    } satisfies DatasetContractDbS,
    return_id: '',
  },
  heatload_forecast: {
    type: 'dbs',
    filter: {},
    contract: {
      global_read_api: true,
      id_renaming: {
        heat: 'heatload_forecast',
      },
      metadata_filter: {
        include: {
          internal_id: ['heat'],
          organization: ['$heatload_forecast_organization'],
          system: ['$heatload_forecast_system'],
          abs_service: ['abs_forecast'],
          percentile: ['$heatload_forecast_percentile'],
        },
        exclude: {},
      },
    } satisfies DatasetContractDbS,
    return_id: '',
  },
  heatload_outcome: {
    type: 'dbs',
    filter: {
      zero_is_nan: true,
    },
    contract: {
      global_read_api: true,
      id_renaming: {
        '$meas_heatload_tag': 'heatload_outcome',
      },
      metadata_filter: {
        include: {
          internal_id: ['$meas_heatload_tag'],
          organization: ['$heatload_organization'],
          system: ['$heatload_system'],
          abs_service: ['abs_meas'],
        },
        exclude: {},
      },
    } satisfies DatasetContractDbS,
    return_id: '',
  },
  base_unit_availability_vv: {
    type: `custom/base_unit_availability_vv`,
    contract: {
      fgc_units: [],
      system_id: `$system_id`,
      wasteheat_units: [],
      total_production_units: [],
    },
    return_id: `base_unit_availability_vv`,
  },
  otk_vv: {
    type: `custom/otk_vv`,
    contract: {
      system_id: `$system_id`,
    },
    return_id: `otk_vv`,
  },
  avv_ack: {
    type: `custom/charge_acc_with_peak_unit`,
    contract: {
      system_id: `$system_id`,
      unit_names: [],
    },
    return_id: `avv_ack`,
  },
  pps: {
    type: `pps`,
    filter: {
      zero_is_nan: true,
    },
    contract: {
      system_id: `$system_id`,
      positive_total: false,
    },
    return_id: `pps`,
  },
  mpk: {
    type: `pps`,
    filter: {
      zero_is_nan: true,
    },
    contract: {
      system_id: `$system_id`,
      positive_total: false,
    },
    return_id: `mpk`,
  },
  ddk: {
    type: `pps`,
    filter: {
      zero_is_nan: true,
    },
    contract: {
      system_id: `$system_id`,
      positive_total: false,
      optimal_opt_job_type_id: 3,
      outcome_opt_job_type_id: 5,
    },
    return_id: `ddk`,
  },
  avk: {
    type: `pps`,
    filter: {
      zero_is_nan: true,
    },
    contract: {
      system_id: `$system_id`,
      positive_total: false,
      optimal_opt_job_type_id: 4,
      outcome_opt_job_type_id: 3,
    },
    return_id: `avk`,
  },
  pps_prod_plan: {
    type: `pps`,
    filter: {
      zero_is_nan: true,
    },
    contract: {
      system_id: `$system_id`,
      positive_total: false,
      optimal_opt_job_type_id: 2,
      outcome_opt_job_type_id: 5,
    },
    return_id: `pps_prod_plan`,
  },
  rated_power: {
    type: `property_values`,
    contract: {
      opt_model_id: `$opt_model_id`,
      object_type: `node`,
      object_name: `main`,
      property_name: `rated_power`,
    },
    return_id: `rated_power`,
  },
}

export default DEFAULT_DATASET_TEMPLATES
