import { ReactNode } from 'react'

import { DigitalTwinTranslations } from 'api/digitalTwin/digitalTwin.api'
import { ChartConfiguration } from 'chart.js'
import _ChartJS from 'chart.js/auto'
import { ZoomPluginOptions } from 'chartjs-plugin-zoom/types/options'

import { DatasetTableProps } from '../DatasetTable/DatasetTable'

import { CrosairOptions } from './helpers/crosairPlugin'
import { SyncYAxisOptions } from './helpers/syncYAxisPlugin'
import { TodayMarkerOptions } from './helpers/todayMarkerPlugin'

export type ChartJS = _ChartJS

export const chartColors = {
  tooltipBackground: `#f9f9f9`,
  black: `#000000`,
  crosshair: `#F66`,
  zoomBoxBackground: `rgba(66,133,244,0.2)`,
  zoomBoxBorder: `#48F`,
  gridLines: `#D0D0D0`,
  greyedFont: `#8F8F8F`,
  white: `#fff`,
}

export type ChartItem = {
  title: string
  data_id: string
  color?: string
  unit?: string
  decimals?: number
  fill?: boolean | number | string
  dashed?: boolean
  y_axis_id?: string
  opacity?: boolean
  config?: Record<string, unknown>
  order?: number | string | undefined
  stack_group?: number
  data_type?: `power` | `energy` | `cost` | `cost/energy`
  tooltip_always_positive?: boolean
  tooltip_do_not_show_zero_values?: boolean
  tooltip_show_time_as_interval?: boolean
  tooltip_do_not_show_value?: boolean
  default_hidden?: boolean
  border_width?: number
  ignore_empty_filter?: boolean
  show_as_negative?: boolean
  h_energy_bar?: boolean
  tag?: boolean
  tags?: string[]
  show_only_in_export?: boolean
  show_only_in_chart?: boolean
  do_not_show_item?: boolean
  is_peak_unit?: boolean
  show_arrow_icon?: boolean
}

export type ChartJSDatasetConfig = {
  label: string
  data: number[]
  backgroundColor?: string
  borderColor?: string
  borderWidth?: number
  borderDash?: number[]
  decimals?: number
  unit?: string
  yAxisID?: string
  fill?: boolean | number | string
  order?: number
  stack?: number
  data_type?: string
  tooltip_always_positive?: boolean
  tooltip_do_not_show_zero_values?: boolean
  tooltip_show_time_as_interval?: boolean
  tooltip_do_not_show_value?: boolean
  hidden?: boolean
  border_width?: number
  hoverBackgroundColor?: string
  show_arrow_icon?: boolean
}

type ExportButtonOptions = {
  display: boolean
}

type ChartLegend = {
  display: boolean
  groupId?: string
  shared?: boolean
}

export type ChartConfigWithZoom = { defaults: { font: { family: string } } } & ChartConfiguration & {
  id: string
  options: {
    plugins: {
      todayMarker?: TodayMarkerOptions
      crosair?: CrosairOptions
      zoom?: ZoomPluginOptions
      exportButton?: ExportButtonOptions
      syncYAxis?: SyncYAxisOptions
      chartLegend?: ChartLegend
    }
    ignoreUpdateDataResolutionOnZoom?: boolean
  }
}

export type FilterItems = {
  data_ids: number[]
  return_ids: string[]
  include_only_base_items_with_attr?: Record<string, boolean | number | string | null>
  default_hidden?: true
  is_mutually_exclusive?: false,
  title: string
}

export type ChartProps = {
  items: ChartItem[]
  config: ChartConfigWithZoom
  datasets: Dataset[]
  children?: ReactNode
  filter_items?: FilterItems[]
  title?: string
  uid?: number
  height?: number
  ignoreAggregate?: boolean
  dataset_table: {default: boolean, display: boolean} & Omit<DatasetTableProps, 'items' | 'datasets' | 'children'>
  translations?: {[key: string]: string}
  hide_legend: boolean
  maxWidth?: string
  digitalTwinTranslations?: DigitalTwinTranslations
  settingInfo?:  { name: string; attribute: string; }
}
