import z from 'zod'

export const TabSchema = z
  .string()
  .or(z.number())
  .pipe(z.coerce.number())
  .refine((value) => value >= 0)

export function getTabFromStorage(key: string, defaultValue: number): number {
  const storeValue = localStorage.getItem(key)
  const res = TabSchema.safeParse(storeValue)
  if (!res.success) {
    return defaultValue
  }

  return res.data
}
