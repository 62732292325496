import React, { useContext } from 'react'

import { Button, DatePickerStateContext } from 'react-aria-components'
import { useTranslation } from 'react-i18next'

import styles from './UntilFurtherNoticeButton.module.less'

export function UntilFurtherNoticeButton(): JSX.Element {
  const { t } = useTranslation()
  const datePickerState = useContext(DatePickerStateContext)

  const handleClick = (): void => {
    datePickerState.setValue(null)
    datePickerState.close()
  }

  return (
    <Button slot={null} className={styles.Button} onPress={handleClick}>
      {t('Until further notice')}
    </Button>
  )
}
