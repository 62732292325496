import React, { ReactElement } from 'react'

import { Hidden, Drawer, useTheme, Typography, Grid } from '@mui/material'
import classNames from 'classnames'
import moment from 'moment'
import { RoutesArray } from 'ui/components/Navigation/types'

import DrawerItems from './DrawerItems/DrawerItems'
import styles from './Sidebar.module.less'


type SidebarProps = {
  drawerOpen: boolean
  setDrawerOpen: (state: boolean) => void
  routes: RoutesArray
}

function Sidebar({ drawerOpen, setDrawerOpen, routes }: SidebarProps): ReactElement {
  const theme = useTheme()
  return <>
    <Hidden smDown>
      <Drawer
        variant="permanent"
        className={classNames(styles.Sidebar_Drawer, {
          [styles.Sidebar_DrawerOpen]: drawerOpen,
          [styles.Sidebar_DrawerClose]: !drawerOpen,
        })}
        classes={{
          paper: classNames(styles.Sidebar_Root, {
            [styles.Sidebar_DrawerOpen]: drawerOpen,
            [styles.Sidebar_DrawerClose]: !drawerOpen,
          }),
        }}
        open={drawerOpen}
      >
        <DrawerItems routes={routes} drawerOpen={drawerOpen} />
        {drawerOpen && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-end"
            className={styles.Sidebar_VersionContainer}
          >
            <Typography className={styles.Sidebar_VersionText}>Aurora by Sigholm {moment().format(`YYYY`)}</Typography>
            <Typography className={styles.Sidebar_VersionText}>
              Version: Sideshow Bob
            </Typography>
          </Grid>
        )}
      </Drawer>
    </Hidden>
    <Hidden smUp>
      <Drawer
        variant="temporary"
        anchor={theme.direction === `rtl` ? `right` : `left`}
        open={!drawerOpen}
        onClose={() => setDrawerOpen(!drawerOpen)}
        classes={{
          paper: classNames(styles.Sidebar_Root, styles.Sidebar_Drawer),
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <DrawerItems routes={routes} drawerOpen={drawerOpen} />
      </Drawer>
    </Hidden>
  </>
}

export default React.memo(Sidebar)
