import { QueryClient } from 'react-query'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 60 * 1000 * 60,
      staleTime: 60 * 1000 * 10,
      refetchOnWindowFocus: false,
    },
  },
})
