import React, { ComponentType, ReactElement, useEffect } from 'react'

import { Route, Redirect } from 'react-router-dom'

type PrivateRouteProps = {
  path: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: ComponentType<any>
  title?: string
  contentComponent?: string
}

export function PrivateRoute({ component: Component, path, title, ...rest }: PrivateRouteProps): ReactElement {
  useEffect(() => {
    document.title = `Aurora by Sigholm${title ? ` - ` + title : ``}`
  }, [title])
  return (
    <Route
      {...rest}
      path={path}
      render={(props) =>
        Component ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to={{ pathname: `/login`, state: { from: props.location } }} />
        )
      }
    />
  )
}
