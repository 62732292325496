/* eslint-disable no-restricted-imports */
import { proxy } from 'valtio'

import { DigitalTwin, DigitalTwinLayout } from '../../../api/digitalTwin/digitalTwin.api'

type DigitalTwinVersionControlStore = {
  digitalTwins: DigitalTwin[] //{ [uid: number]: DigitalTwin }
  // versions: Record<number, number[]> //Record<UiConfigId, UiConfigUid[]>
  currentDigitalTwin?: DigitalTwin
}

const digitalTwinVersionControlStore = proxy<DigitalTwinVersionControlStore>({
  currentDigitalTwin: undefined,
  digitalTwins: [],
  // versions: {},
})

export function setCurrentDigitalTwin(currentDigitalTwin?: DigitalTwin): void {
  digitalTwinVersionControlStore.currentDigitalTwin = currentDigitalTwin
}

export function setCurrentDigitalTwinLayout(layout: DigitalTwinLayout): void {
  if (digitalTwinVersionControlStore.currentDigitalTwin){
    digitalTwinVersionControlStore.currentDigitalTwin.layout = layout
  }
}

export function addDigitalTwin(digitalTwin: DigitalTwin): void {
  digitalTwinVersionControlStore.digitalTwins.push(digitalTwin)

}

export function addDigitalTwins(newDigitalTwins: DigitalTwin[]): void {
  digitalTwinVersionControlStore.digitalTwins = newDigitalTwins
}

export default digitalTwinVersionControlStore