export type SecondaryColors = {
  main: React.CSSProperties[`color`]
  hover: React.CSSProperties[`color`]
  disabled: React.CSSProperties[`color`]
  contrastText: React.CSSProperties[`color`]
}

const secondary = {
  main: `#12476d`,
  hover: `#0d334f`,
  disabled: `#4d7c9e`,
  contrastText: `#fff`,
}

export default secondary
