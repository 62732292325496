import React, { ReactElement, useMemo, useRef } from 'react'

import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from '@mui/material'

import styles from './Tooltip.module.less'

/**
 * Wrapper for mui `Tooltip`. Use within `CardWidget` for tooltips to work in full screen.
 **/

type TooltipProps = {
  display?: 'block' | 'contents' | 'inline-block'
}

export default function Tooltip({ children, display, ...props }: TooltipProps & MuiTooltipProps): ReactElement {
  const ref = useRef<HTMLDivElement | null>(null)
  const style: Record<string, string> = {}

  if (display) {
    style.display = display
  }

  // NOTE: MUI throws error when tooltip is wrapping disabled button since disabled elements to not emit events, see: https://mui.com/material-ui/react-tooltip/#disabled-elements
  const wrapDisabledButton = useMemo(() => {
    try {
      const isDisabled = React.Children.only(children).props.disabled
      return isDisabled
    } catch (err: any) {
      return false
    }
  }, [children])


  return (
    <div ref={ref} className={styles.Tooltip} style={style}>
      <MuiTooltip PopperProps={{ container: ref.current }} {...props}>
        {wrapDisabledButton ? <div>{children}</div> : children}
      </MuiTooltip>
    </div>
  )
}
