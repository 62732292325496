import { apiClient } from 'api/apiClient/apiClient'
import { useQuery } from 'react-query'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import { NotificationType } from 'ui/components/NotificationCenter/NotificationCenter.helper'



export const NOTIFICATIONS_QUERY_KEY = `notifications`

type NotificationApi = {
  startTime?: ISODateTime,
  endTime?: ISODateTime,
  order?: string,
  exclude?: string,
  type?: string,
}

export function useNotifications({startTime, endTime, order, exclude, type}: NotificationApi): unknown {
  const { activeSystem } = useAuth()
  return useQuery<NotificationType>(
    [NOTIFICATIONS_QUERY_KEY, { system: activeSystem?.id, startTime: startTime, endTime: endTime, order: order, exclude: exclude, type: type }],
    async () => {
      const response = await apiClient<NotificationType>(`systems/${activeSystem?.id}/notifications`, {
        params: {
          order: order ?? '-updated_at',
          limit: 50,
          exclude: exclude ?? '',
          type: type ?? '',
          start_time: startTime ?? '',
          end_time: endTime ?? '',
        },
      })
      return response
    },
    {
      enabled: true,
    }
  )
}
