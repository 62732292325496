import 'moment/dist/locale/sv'
import 'moment/dist/locale/en-gb'
import 'moment/dist/locale/fi'

import moment from 'moment'

import { localizationMapping, getWindowLocale } from 'localization/localization.helper'

export default function init(): void {
  const locale = localStorage.getItem(`locale`)
  const language = localStorage.getItem(`language`)

  if (!locale && !language) {
    localStorage.setItem(`locale`, window.navigator.language)
  }

  window.locale = !language ? (locale ?? window.navigator.language) : language

  const localeValue = localizationMapping(getWindowLocale())
  moment.updateLocale(localeValue ?? ``, {
    week: {
      dow: 1,
      doy: 4,
    },
  })

  moment.defaultFormat = `YYYY-MM-DD HH:mm`
}
