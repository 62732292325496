import React, { PropsWithChildren, ReactElement } from 'react'

import { PusherProvider } from '@harelpls/use-pusher'
import { CssBaseline } from '@mui/material'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { I18nProvider } from 'react-aria-components'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Router } from 'react-router-dom'
import theme from 'styles/theme/theme'
import { AlertProvider } from 'ui/components/AlertContext/AlertContext'
import { AuthProvider } from 'ui/components/AuthContext/AuthContext'
import { ChartSettingsProvider } from 'ui/components/ChartSettingsContext/ChartSettingsContext'

import { history } from 'helpers/history'
import { queryClient } from 'helpers/queryClient'
import { languageToLocalization, getLanguage } from 'localization/localization.helper'

const pusherConfig = {
  // required config props
  clientKey: process.env.REACT_APP_PUSHER_KEY,
  cluster: `eu`,
}

export default function AllProviders({ children }: PropsWithChildren<Record<string, unknown>>): ReactElement {
  const locale = languageToLocalization(getLanguage())

  return (
    <Router history={history}>
      <QueryClientProvider client={queryClient}>
        <I18nProvider locale={locale}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <PusherProvider {...pusherConfig}>
                <ChartSettingsProvider>
                  <AuthProvider>
                    <AlertProvider>
                      <CssBaseline />
                      {children}
                    </AlertProvider>
                  </AuthProvider>
                </ChartSettingsProvider>
              </PusherProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </I18nProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Router>
  )
}
