import { t } from 'i18next'
import elplanStore from 'store/elplan/elplan'
import { snapshot } from 'valtio'

import { clone } from 'helpers/global.helper/global.helper'

export function parseWildcardItems(items: UiConfigPropsItem[]): UiConfigPropsItem[] {
  const { bid_types } = snapshot(elplanStore)
  const newItems: UiConfigPropsItem[] = []

  items.forEach((item) => {
    if (item?.data_id?.includes('*')) {
      const bidType = bid_types.find((bidType) => item.data_id.includes(bidType.type) && item.data_id.includes('*'))
      if (bidType) {
        const wildCardAmount = bidType.amount ?? 10
        for (let i = 1; i < wildCardAmount; i++) {
          const newItem = clone(item)
          newItem.data_id = getWildCardReturnId(item.data_id, i)
          const translatedTitle = bidType.type === 'blockbid' ? t('Block bid') : bidType.type === 'profilebid' ? t('Profile bid') : 'bid'
          const modifiedTitle = translatedTitle[0].toUpperCase() + translatedTitle.slice(1)

          newItem.title = `${modifiedTitle} ${i}`

          newItem.color = getBidColorBasedOnOrderAndType(bidType.type as ('blockbid' | 'profilebid'), i)
          newItems.push(newItem)
        }
      }
      //TODO: Handle other wildcard cases beside for block bid and profile bid (but how would we know how many * we should generate?)
    }
  })
  const filteredItems = items.filter((item) => !item?.data_id?.includes('*'))
  return filteredItems.concat(newItems)
}

export function parseWildcardVariables(variables: (string | number)[]): (string | number)[] {
  const { elplan_info_id, bid_types } = snapshot(elplanStore)

  if (elplan_info_id === 0) { //make sure we do not do this unless the system has a elplan module
    return variables
  }

  const newVariables: string[] = []
  variables.forEach((variable) => {
    if (typeof variable === 'string') {
      const bidType = bid_types.find((bidType) => variable.includes(bidType.type) && variable.includes('*'))

      if (bidType) {
        const wildCardAmount = bidType.amount ?? 10
        for (let i = 1; i < wildCardAmount; i++) {
          const wildCardReturnId = getWildCardReturnId(variable, i)
          newVariables.push(wildCardReturnId)
        }

      }
      //TODO: Handle other wildcard cases beside for block bid and profile bid (but how would we know how many * we should generate?)
    }
  })

  const withoutWildCard = variables.filter((variable) => (typeof variable === 'string' && !variable.includes('*')) || typeof variable === 'number')
  return withoutWildCard.concat(newVariables)
}

function getWildCardReturnId(string: string, index: number): string {
  return `${string.split('*')[0]}${index}`

}

export function getBidColorBasedOnOrderAndType(bidType: 'blockbid' | 'profilebid', secondIndex: number): string {
  const colors1 = ['#73d453', '#539ed4', '#f35e5e', '#66bb6a', '#fa67aa', '#bb63ff', '#53d49e', '#7353d4', '#FFA500', '#FFD700']
  const colors2 = ['#BFEFFF', '#BDFCC9', '#ffbdd7', '#DABDFF', '#FFF9C6', '#A6E3E3', '#BFFFD9', '#FFDABD', '#FFFD9C', '#ffbebd']

  if (bidType === 'blockbid') {
    return colors1[secondIndex % colors1.length]
  }
  if (bidType === 'profilebid') {
    return colors2[secondIndex % colors1.length]
  }
  return 'pink'

}

