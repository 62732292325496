export const colors: Record<string, string> = {
  primary1: `#ecfdff`,
  primary2: `#b3e5ee`,
  primary3: `#5ea9b7`,
  primary4: `#198ca1`,
  primary5: `#016b80`,
  primary6: `#073841`,
  primary7: `#152029`,

  secondary1: `#ecf7ff`,
  secondary2: `#a1c6e0`,
  secondary3: `#539ed4`,
  secondary4: `#1b72b8`,
  secondary5: `#17619e`,
  secondary6: `#14476d`,
  secondary7: `#0d334f`,

  accent1: `#fecbe2`,
  accent2: `#fc9dc8`,
  accent3: `#fa67aa`,
  accent4: `#e93989`,
  accent5: `#d02573`,
  accent6: `#783173`,
  accent7: `#4c2a67`,

  white: `#ffffff`,
  grey0: `#ffffff`,
  grey1: `#f9f9f9`,
  grey2: `#f2f2f2`,
  grey3: `#e2e2e2`,
  grey4: `#cacaca`,
  grey5: `#8F8F8F`,
  grey6: `#757575`,
  grey7: `#373737`,
  grey8: `#222222`,
  black: `#000000`,

  info1: `#ecf7ff`,
  info2: `#a1c6e0`,
  info3: `#539ed4`,
  info4: `#1b72b8`,
  info5: `#17619e`,
  info6: `#14476d`,
  info7: `#0d334f`,

  success1: `#e8f5e9`,
  success2: `#a5d6a7`,
  success3: `#66bb6a`,
  success4: `#43a047`,
  success5: `#388e3c`,
  success6: `#1b5e20`,
  success7: `#17501b`,

  warning1: `#fff6d8`,
  warning2: `#ffe19f`,
  warning3: `#ffdc6d`,
  warning4: `#f1c202`,
  warning5: `#db8b23`,
  warning6: `#c55c1c`,
  warning7: `#944210`,

  danger1: `#ffc7c7`,
  danger2: `#ff9393`,
  danger3: `#f35e5e`,
  danger4: `#e0182d`,
  danger5: `#b9172e`,
  danger6: `#9b1a1f`,
  danger7: `#7c0f14`,

  crosshair: `#F66`,
  zoomBoxBackground: `rgba(66,133,244,0.2)`,
  zoomBoxBorder: `#48F`,
  gridLines: `#D0D0D0`,
  greyedFont: `#8F8F8F`,
  tooltipBackground: `#f9f9f9`,
}
