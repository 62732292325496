import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import { ACTIVE_SYSTEM_LOCAL_STORAGE_KEY } from 'store/auth/auth'

import createCustomBackend from './customBackend'

const language = (localStorage.getItem('language') ?? localizationMapping(getWindowLocale())) as LanguageCode

const activeSystemItem = localStorage.getItem(ACTIVE_SYSTEM_LOCAL_STORAGE_KEY)
const activeSystem: System | null = activeSystemItem ? JSON.parse(activeSystemItem) : null
const tokens = getTransifexToken(activeSystem)
const mainBackend = createCustomBackend({tokens: tokens})

i18next
  .use(initReactI18next)
  .use(mainBackend)
  .init({
    lng: language,
    fallbackLng: 'en', // use en if detected lng is not available
    defaultNS: 'translation',
    ns: ['translation'],
    fallbackNS: 'translation',
    keySeparator: false, // we do not use keys in form messages.welcome in transifex
    interpolation: {
      escapeValue: false,
    },
    i18nFormat: {
      bindI18nStore: 'added',
    },
    react: {
      bindI18nStore: 'added',
      useSuspense: true,
    },
  })

export default i18next

export function getLanguage(): LanguageCode {
  return (localStorage.getItem('language') ?? localizationMapping(getWindowLocale())) as LanguageCode
}

export function getWindowLocale(): string {
  return window.locale ?? `en-GB`
}

export function localizationMapping(locale: string): LanguageCode {
  switch (locale) {
    case 'sv':
    case 'sv-SE':
      return 'sv'
    case 'en':
    case 'en-US':
      return 'en'
    case 'fi':
    case 'fi-FI':
      return 'fi'
    default:
      return 'en'
  }
}

export function languageToTimeZone(language: LanguageCode): string {
  switch (language) {
    case 'sv':
      return 'Europe/Stockholm'
    case 'en':
      return 'Europe/Stockholm'
    case 'fi':
      return 'Europe/Helsinki'
    default:
      return 'Europe/Stockholm'
  }
}

export function languageToLocalization(language: LanguageCode): string {
  switch (language) {
    case 'sv':
      return 'sv-SE'
    case 'en':
      return 'en-GB'
    case 'fi':
      return 'fi-FI'
    default:
      return 'en-GB'
  }
}

function getTransifexToken(system: System | null): string[] {
  /* Appends the organization token to the list of tokens last so that it overrides the standard translations */
  const tokens = [process.env.REACT_APP_TX_TOKEN]
  if (system?.custom_translation_token) {
    tokens.push(system.custom_translation_token)
  }
  return tokens
}
