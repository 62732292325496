export type UiColors = {
  one: React.CSSProperties[`color`]
  two: React.CSSProperties[`color`]
  three: React.CSSProperties[`color`]
  four: React.CSSProperties[`color`]
  five: React.CSSProperties[`color`]
  six: React.CSSProperties[`color`]
  seven: React.CSSProperties[`color`]
  eight: React.CSSProperties[`color`]
}

const ui = {
  one: `#fff`,
  two: `#f2f2f2`,
  three: `#c2e6ff`,
  four: `#455057`,
  five: `#152029`,
  six: `#328291`,
  seven: `#d6d6d6`,
  eight: `#5ea9b7`,
}

export default ui
