import React, { ReactElement } from 'react'

import { dynamicClassName } from 'styles/helper'

import styles from './Icon.module.less'

type IconProps = {
  icon?: string
  size?: 'small' | 'medium' | 'large'
  large?: boolean
  color?: string
  onClick?: (() => void) | ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void)
}

const iconColorClasses: Record<string, string> = {
  white: styles.Icon_Color__white,
  black: styles.Icon_Color__black,
  pink: styles.Icon_Color__pink,
  blue: styles.Icon_Color__blue,
  red: styles.Icon_Color__red,
  green: styles.Icon_Color__green,
  dark_green: styles.Icon_Color__dark_green,
  grey: styles.Icon_Color__grey,
  yellow: styles.Icon_Color__yellow,
  purple: styles.Icon_Color__purple,
  turquoise: styles.Icon_Color__turquoise,
  orange: styles.Icon_Color__orange,
}

export default function Icon({ icon, size, color, onClick, large }: IconProps): ReactElement {
  const hasCssClassForColor = color !== undefined && Object.keys(iconColorClasses).includes(color)
  const colorCssClasses = hasCssClassForColor ? [iconColorClasses[color]] : undefined

  return (
    <i
      className={dynamicClassName(
        {
          [icon + ` ` + styles.Icon]: true,
          [styles.Icon__large]: !!(size === 'large') || !!large,
          [styles.Icon__medium]: !!(size === 'medium'),
          [styles.Icon__small]: !!(size === 'small'),
          [styles.Icon_Event]: !!onClick,
        },
        colorCssClasses
      )}
      style={!hasCssClassForColor ? { color: color } : undefined} // Only add style if we don't have a css class for the color
      onClick={(ev) => {
        onClick && onClick(ev)
      }}
    />
  )
}
