import React from 'react'

import { CalendarDate, getWeeksInMonth } from '@internationalized/date'
import { useCalendarGrid } from 'react-aria'
import {
  CalendarCell,
  CalendarStateContext,
  useLocale,
  type CalendarGridProps as ReactAriaCalendarGridProps,
} from 'react-aria-components'
import { useTranslation } from 'react-i18next'

import calendarCellStyles from './CalendarCell.module.less'
import styles from './CalendarGrid.module.less'

type CalendarGridProps = ReactAriaCalendarGridProps & {
  showWeekNumbers?: boolean
  visualSelection?: 'week' | 'day'
}

export function CalendarGrid(props: CalendarGridProps): JSX.Element {
  const state = React.useContext(CalendarStateContext)
  const { gridProps, headerProps, weekDays } = useCalendarGrid(props, state)
  const { locale } = useLocale()
  const startDate = state.visibleRange.start
  const weeksInMonth = getWeeksInMonth(startDate, locale)
  const visualSelection = props.visualSelection || 'day'
  const { t } = useTranslation()

  function isToday(date: CalendarDate): boolean {
    const today = new Date()
    return date.year === today.getFullYear() &&
           date.month === today.getMonth() + 1 && // month is 0-indexed in JavaScript Date
           date.day === today.getDate()
  }

  return (
    <table {...gridProps} className={styles.Table} data-selection-mode={visualSelection}>
      <thead>
        <tr {...headerProps}>
          {props.showWeekNumbers && <th />}
          {weekDays.map((day, i) => (
            <th key={i} className={styles.Grid_days}>
              {day}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(weeksInMonth)].map((_, weekIndex) => {
          const datesInWeek = state.getDatesInWeek(weekIndex, startDate)
          return (
            <tr key={weekIndex} className={styles.Week_row}>
              {props.showWeekNumbers && (
                <td className={styles.Grid_weekNumber} data-type="weeknumber">
                  <p>{t(`w.`)}</p>
                  {getWeekNumber(new Date(datesInWeek[0]!.year, datesInWeek[0]!.month - 1, datesInWeek[0]!.day))}
                </td>
              )}
              {datesInWeek.map((date, i) => {
                if (!date) {
                  return <td key={i} />
                }

                const isFirstDayOfWeek = i === 0
                const isLastDayOfWeek = i === 6
                const todayHighlight = isToday(date)

                return (
                  <CalendarCell
                    key={i}
                    data-first-day-of-week={isFirstDayOfWeek}
                    data-last-day-of-week={isLastDayOfWeek}
                    data-testid="calendar-cell"
                    className={`${calendarCellStyles.Cell} ${todayHighlight ? calendarCellStyles.Cell_toDay : ''}`}
                    date={date}
                  />
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

function getWeekNumber(date: Date): number {
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
  date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7))
  const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1))
  const weekNumber = Math.ceil(((Number(date) - Number(yearStart)) / 86400000 + 1) / 7)
  return weekNumber
}
