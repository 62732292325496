import { apiClient } from 'api/apiClient/apiClient'
import i18n from 'i18next'
import authStore from 'store/auth/auth'
import uiConfigStore from 'store/uiConfig/uiConfig'
import { DEFAULT_FORECAST_CONFIG, DEFAULT_PROD_PLAN_CONFIG, xAxisGridSettings, yAxisGridSettingsDisplayTrue } from 'ui/uiConfig/components/Chart/chart.constants'
import { OPTJOB_TYPE_ID_TO_NAME } from 'ui/uiConfig/template/aliases'
import { reviewForecasts, reviewForecastChart, reviewAccuracyKpi } from 'ui/uiConfig/template/moduleTemplates/reviewForecasts'
import { snapshot } from 'valtio'

import { hasSources } from 'helpers/dataset.helper/dataset.helper'
import { clone, getCurrency, merge } from 'helpers/global.helper/global.helper'


async function createUiConfig(config: UiConfig): Promise<UiConfig> {
  const authSnap = snapshot(authStore)
  const system = authSnap.systemId

  if (!system) {
    throw Error('Can not generate followup_production with no systemId')
  }

  return await apiClient<UiConfig>(`ui_config`, {
    method: `POST`,
    data: { ...clone(config), system },
  })
}

const uiConfigWithDefaults: UiConfig = {
  display_name: '',
  props: {
    'use_default': true,
  },
  dataset_instructions: [],
  alias: {},
}

export const followupProdOptimalChart: UiConfig = {
  display_name: `[chart] Followup prod - optimal`,
  component: `chart`,
  id: -3,
  uid: -3,
  alias: {
    opt_job_type_id: 4,
    optjob_type: OPTJOB_TYPE_ID_TO_NAME[4],
  },
  props: {
    items: [
      {
        color: `$color-rated-power`,
        order: -1,
        title: `__Rated power for production`,
        data_id: `rated_power`,
        data_type: `power`,
      },
    ],
    base_items: {
      all: true,
    },
    config: merge(clone(DEFAULT_PROD_PLAN_CONFIG), {
      options: {
        plugins: {
          chartLegend: {display: true, shared: true},
          exportButton: { display: false },
        },
      },
    }),
  },
  dataset_instructions: [
    {
      type: `template`,
      filter: {
        end_time: `$end_time`,
        start_time: `$start_time`,
      },
      return_id: `rated_power`,
    },
  ],
}

export const followupProdOptimalExclRatedPowerChart: UiConfig = {
  display_name: `[chart] Followup prod - optimal (excl. rated power)`,
  component: `chart`,
  id: -16,
  uid: -16,
  alias: {
    opt_job_type_id: 4,
    optjob_type: OPTJOB_TYPE_ID_TO_NAME[4],
  },
  props: {
    items: [
    ],
    base_items: {
      all: true,
    },
    config: merge(clone(DEFAULT_PROD_PLAN_CONFIG), {
      options: {
        plugins: {
          exportButton: { display: false },
          chartLegend: {display: true, shared: true},
        },
      },
    }),
  },
  dataset_instructions: [],
}

export const followupInclusiveDeviationsOutcomeChart: UiConfig = {
  display_name: `[chart] Followup inc. deviations - outcome`,
  component: `chart`,
  id: -4,
  uid: -4,
  alias: {
    opt_job_type_id: 5,
    optjob_type: OPTJOB_TYPE_ID_TO_NAME[5],
  },
  props: {
    items: [
      {
        color: `$color-odc`,
        order: 0,
        title: `__ODC`,
        data_id: `ddk`,
        decimals: 1,
        data_type: `cost`,
        y_axis_id: `yCost`,
      },
      {
        color: `$color-rated-power`,
        order: -1,
        title: `__Rated power for production`,
        data_id: `rated_power`,
        data_type: `power`,
      },
    ],
    base_items: {
      all: true,
    },
    config: merge(clone(DEFAULT_PROD_PLAN_CONFIG), {
      options: {
        plugins: {
          chartLegend: { display: false, shared: true },
          exportButton: { display: false },
        },
      },
    }),
  },
  dataset_instructions: [
    {
      type: `template`,
      filter: {
        end_time: `$end_time`,
        limit_min: 0,
        start_time: `$start_time`,
      },
      return_id: `ddk`,
    },
    {
      type: `template`,
      filter: {
        end_time: `$end_time`,
        start_time: `$start_time`,
      },
      return_id: `rated_power`,
    }, {
      'type': 'optresults',
      'return_id': '',
      'contract': {
        'sources': [
          {
            'source': '$demand_heatload',
            'return_id': '$demand_heatload',
          },
        ],
        'opt_model_id': '$opt_model_id',
        'opt_job_type_id': 3,
        'opt_time': '$opt_time',
      },
      'filter': {
        'start_time': '$start_time',
        'end_time': '$end_time',
        'offset_start_time': '$offset_start_time',
        'offset_end_time': '$offset_end_time',
        'aggregate': '',
      },
    },
  ],
}

export const followupInclusiveDeviationsOutcomeExklRatedPowerChart: UiConfig = {
  display_name: `[chart] Followup inc. deviations - outcome (excl. rated power)`,
  component: `chart`,
  id: -17,
  uid: -17,
  alias: {
    opt_job_type_id: 5,
    optjob_type: OPTJOB_TYPE_ID_TO_NAME[5],
  },
  props: {
    items: [
      {
        color: `$color-odc`,
        order: 0,
        title: `__ODC`,
        data_id: `ddk`,
        decimals: 1,
        data_type: `cost`,
        y_axis_id: `yCost`,
      },
    ],
    base_items: {
      all: true,
    },
    config: merge(clone(DEFAULT_PROD_PLAN_CONFIG), {
      options: {
        plugins: {
          chartLegend: { display: false, shared: true },
          exportButton: { display: false },
        },
      },
    }),
  },
  dataset_instructions: [
    {
      type: `template`,
      filter: {
        end_time: `$end_time`,
        limit_min: 0,
        start_time: `$start_time`,
      },
      return_id: `ddk`,
    },
  ],
}

export const followupProdPlanOutcomeChart: UiConfig = {
  display_name: `[chart] Followup prod. plan - outcome`,
  component: `chart`,
  id: -5,
  uid: -5,
  alias: {
    opt_job_type_id: 5,
    optjob_type: OPTJOB_TYPE_ID_TO_NAME[5],
  },
  props: {
    items: [
      {
        color: `$color-pps`,
        order: 0,
        title: `__PPS (production plan)`,
        data_id: `pps_prod_plan`,
        decimals: 1,
        data_type: `cost`,
        y_axis_id: `yCost`,
      },
      {
        color: `$color-rated-power`,
        order: -1,
        title: `__Rated power for production`,
        data_id: `rated_power`,
        data_type: `power`,
      },
    ],
    base_items: {
      all: true,
    },
    config: merge(clone(DEFAULT_PROD_PLAN_CONFIG), {
      options: {
        plugins: {
          chartLegend: { display: false, shared: true },
          exportButton: { display: false },
        },
      },
    }),
  },
  dataset_instructions: [
    {
      type: `template`,
      filter: {
        end_time: `$end_time`,
        limit_min: 0,
        start_time: `$start_time`,
      },
      return_id: `pps_prod_plan`,
    },
    {
      type: `template`,
      filter: {
        end_time: `$end_time`,
        start_time: `$start_time`,
      },
      return_id: `rated_power`,
    },
  ],
}

export const followupProdPlanOutcomeExclRatedPowerChart: UiConfig = {
  display_name: `[chart] Followup prod. plan - outcome (excl. rated power)`,
  component: `chart`,
  id: -18,
  uid: -18,
  alias: {
    opt_job_type_id: 5,
    optjob_type: OPTJOB_TYPE_ID_TO_NAME[5],
  },
  props: {
    items: [
      {
        color: `$color-pps`,
        order: 0,
        title: `__PPS (production plan)`,
        data_id: `pps_prod_plan`,
        decimals: 1,
        data_type: `cost`,
        y_axis_id: `yCost`,
      },
    ],
    base_items: {
      all: true,
    },
    config: merge(clone(DEFAULT_PROD_PLAN_CONFIG), {
      options: {
        plugins: {
          chartLegend: { display: false, shared: true },
          exportButton: { display: false },
        },
      },
    }),
  },
  dataset_instructions: [
    {
      type: `template`,
      filter: {
        end_time: `$end_time`,
        limit_min: 0,
        start_time: `$start_time`,
      },
      return_id: `pps_prod_plan`,
    },
  ],
}

export const followupExclusiveDeviationsOutcomeChart: UiConfig = {
  display_name: `[chart] Followup excl. deviations - outcome`,
  component: `chart`,
  id: -6,
  uid: -6,
  alias: {
    opt_job_type_id: 5,
    optjob_type: OPTJOB_TYPE_ID_TO_NAME[5],
  },
  props: {
    items: [
      {
        title: `__PPS`,
        data_id: `pps`,
        data_type: `cost`,
        color: `$color-pps`,
        decimals: 1,
        order: 0,
        y_axis_id: `yCost`,
      },
      {
        color: `$color-rated-power`,
        order: -1,
        title: `__Rated power for production`,
        data_id: `rated_power`,
        data_type: `power`,
      },
    ],
    base_items: {
      all: true,
    },
    config: merge(clone(DEFAULT_PROD_PLAN_CONFIG), {
      options: {
        plugins: {
          chartLegend: { display: false, shared: true },
          exportButton: { display: false },
        },
      },
    }),
  },
  dataset_instructions: [
    {
      type: `template`,
      filter: {
        start_time: `$start_time`,
        limit_min: 0,
        end_time: `$end_time`,
      },
      contract: {},
      return_id: `pps`,
    },
    {
      type: `template`,
      filter: {
        end_time: `$end_time`,
        start_time: `$start_time`,
      },
      return_id: `rated_power`,
    }, {
      'type': 'optresults',
      'return_id': '',
      'contract': {
        'sources': [
          {
            'source': '$demand_heatload',
            'return_id': '$demand_heatload',
          },
        ],
        'opt_model_id': '$opt_model_id',
        'opt_job_type_id': 4,
        'opt_time': '$opt_time',
      },
      'filter': {
        'start_time': '$start_time',
        'end_time': '$end_time',
        'offset_start_time': '$offset_start_time',
        'offset_end_time': '$offset_end_time',
        'aggregate': '',
      },
    },
  ],
}

export const followupExclusiveDeviationsOutcomeExclRatedPowerChart: UiConfig = {
  display_name: `[chart] Followup excl. deviations - outcome (excl. rated power)`,
  component: `chart`,
  id: -19,
  uid: -19,
  alias: {
    opt_job_type_id: 5,
    optjob_type: OPTJOB_TYPE_ID_TO_NAME[5],
  },
  props: {
    items: [
      {
        title: `__PPS`,
        data_id: `pps`,
        data_type: `cost`,
        color: `$color-pps`,
        decimals: 1,
        order: 0,
        y_axis_id: `yCost`,
      },
    ],
    base_items: {
      all: true,
    },
    config: merge(clone(DEFAULT_PROD_PLAN_CONFIG), {
      options: {
        plugins: {
          chartLegend: { display: false, shared: true },
          exportButton: { display: false },
        },
      },
    }),
  },
  dataset_instructions: [
    {
      type: `template`,
      filter: {
        start_time: `$start_time`,
        limit_min: 0,
        end_time: `$end_time`,
      },
      contract: {},
      return_id: `pps`,
    },
  ],
}

export const realTimeOptimizationBars: UiConfig = {
  display_name: '[real time opt. bars]',
  component: 'real_time_optimization_bars',
  id: -20,
  uid: -20,
  props: {
    show_warning_at_age_in_minute: 15,
    card_width: 150,
    highlight_diff_at: 2,
    base_items: {
      all: true,
    },
    stack_groups: [
      {
        id: 0,
        title: `__Optimal`,
      },
      {
        id: 1,
        title: `__Outcome`,
      },
    ],
    base_items_filter: {
      aggregate: `latest`,
    },
    multi_base_items_override: [
      {
        item: {
          stack_group: 0,
        },
        alias: {
          opt_job_type_id: `$optimal_opt_job_type_id`,
          optjob_type: '$optimal_optjob_type',
        },
        return_id_prefix: `optimal`,
      },
      {
        item: {
          stack_group: 1,
        },
        alias: {
          opt_job_type_id: `$outcome_opt_job_type_id`,
          optjob_type: '$outcome_optjob_type',
        },
        return_id_prefix: `outcome`,
      },
    ],
  },
  dataset_instructions: [],
  alias: {
    optimal_opt_job_type_id: 12,
    optimal_optjob_type: OPTJOB_TYPE_ID_TO_NAME[12],
    outcome_opt_job_type_id: 13,
    outcome_optjob_type: OPTJOB_TYPE_ID_TO_NAME[13],
  },
}

export const followupProdTotalEnergyHorizontalBars: UiConfig = {
  display_name: `[hori.bars] Followup prod - total energy`,
  component: `horizontal_bars`,
  id: -7,
  uid: -7,
  props: {
    title: `__Total energy`,
    data_type: 'energy',
    base_items: {
      all: true,
    },
    include_only_base_items_with_attr: {
      h_energy_bar: true,
    },
    stack_groups: [
      {
        id: 0,
        title: `__Optimal`,
      },
      {
        id: 1,
        title: `__Outcome`,
      },
    ],
    base_items_filter: {
      aggregate: `sum`,
      aggregate_in_frontend: true,
    },
    multi_base_items_override: [
      {
        item: {
          stack_group: 0,
          decimals: 2,
        },
        alias: {
          opt_job_type_id: `$optimal_opt_job_type_id`,
          optjob_type: '$optimal_optjob_type',
        },
        return_id_prefix: `optimal`,
      },
      {
        item: {
          stack_group: 1,
          decimals: 2,
        },
        alias: {
          opt_job_type_id: `$outcome_opt_job_type_id`,
          optjob_type: '$outcome_optjob_type',
        },
        return_id_prefix: `outcome`,
      },
    ],
  },
  dataset_instructions: [],
  alias: {
    optimal_opt_job_type_id: 3,
    optimal_optjob_type: OPTJOB_TYPE_ID_TO_NAME[3],
    outcome_opt_job_type_id: 5,
    outcome_optjob_type: OPTJOB_TYPE_ID_TO_NAME[5],
  },
}

export const followupProdTotalCostHorizontalBars: UiConfig = {
  display_name: `[hori.bars] Followup prod - total cost`,
  component: `horizontal_bars`,
  id: -8,
  uid: -8,
  props: {
    items: [
      {
        unit: ``,
        color: `$color-1`,
        order: 0,
        title: `__Total cost`,
        data_id: `optimal.total_cost`,
        decimals: 2,
        data_type: `cost`,
        stack_group: 0,
      },
      {
        unit: ``,
        color: `$color-1`,
        order: 0,
        title: `__Total cost`,
        data_id: `outcome.total_cost`,
        decimals: 2,
        data_type: `cost`,
        stack_group: 1,
      },
    ],
    title: `__Total cost`,
    stack_groups: [
      {
        id: 0,
        title: `__Optimal`,
      },
      {
        id: 1,
        title: `__Production outcome`,
      },
    ],
  },
  dataset_instructions: [
    {
      'type': 'dbs',
      'filter': {
        'aggregate': 'sum',
        'end_time': '$end_time',
        'start_time': '$start_time',
        'aggregate_in_frontend': true,
        'created_at': '$created_at',
      },
      'contract': {
        'global_read_api': false,
        'id_renaming': {
          'total_cost': 'optimal.total_cost',
        },
        'metadata_filter': {
          'include': {
            'organization': [
              '$organization',
            ],
            'system': [
              '$system',
            ],
            'abs_service': [
              'abs_optservice',
            ],
            'optjob_type': [
              '$optimal_optjob_type',
            ],
            'internal_id': [
              'total_cost',
            ],
          },
          'exclude': {},
        },
      },
      'return_id': '',
    },
    {
      'type': 'dbs',
      'filter': {
        'aggregate': 'sum',
        'end_time': '$end_time',
        'start_time': '$start_time',
        'aggregate_in_frontend': true,
        'created_at': '$created_at',
      },
      'contract': {
        'global_read_api': false,
        'id_renaming': {
          'total_cost': 'outcome.total_cost',
        },
        'metadata_filter': {
          'include': {
            'organization': [
              '$organization',
            ],
            'system': [
              '$system',
            ],
            'abs_service': [
              'abs_optservice',
            ],
            'optjob_type': [
              '$outcome_optjob_type',
            ],
            'internal_id': [
              'total_cost',
            ],
          },
          'exclude': {},
        },
      },
      'return_id': '',
    },
  ],
  alias: {
    outcome_opt_job_type_id: 5,
    outcome_optjob_type: OPTJOB_TYPE_ID_TO_NAME[5],
    optimal_opt_job_type_id: 4,
    optimal_optjob_type: OPTJOB_TYPE_ID_TO_NAME[4],
  },
}

export const followupProductionKpi: UiConfig = {
  display_name: `[FollowupProdKpi] Followup production kpi`,
  component: `follow_up_prod_kpi`,
  props: {
    items: [
      {
        bar: true,
        unit: ``,
        color: `$color-pps`,
        title: `__PPS`,
        data_id: `mpk_sum`,
        tooltip: `__Potential production saving`,
        decimals: 2,
        data_type: `cost`,
        stack_group: 1,
      },
      {
        bar: true,
        unit: ``,
        color: `$color-odc`,
        title: `__ODC`,
        data_id: `ddk_sum`,
        tooltip: `__Operational deviation costs`,
        decimals: 2,
        data_type: `cost`,
        stack_group: 1,
      },
      {
        bar: true,
        unit: ``,
        color: `$color-lmfu`,
        title: `__LMFU (lost margin from unavailability)`,
        data_id: `avk_sum`,
        tooltip: `__Lost margin from unavailability`,
        decimals: 2,
        data_type: `cost`,
        stack_group: 1,
      },
      {
        unit: ``,
        color: `$color-pps`,
        title: `__PPS average`,
        data_id: `mpk_mean`,
        tooltip: `__Potential production saving average`,
        decimals: 2,
        data_type: `cost/energy`,
        stack_group: 1,
      },
    ],
  },
  dataset_instructions: [
    {
      type: 'calc',
      filter: {
        end_time: '$end_time',
        aggregate: 'sum',
        limit_min: 0,
        zero_is_nan: true,
        start_time: '$start_time',
        aggregate_in_frontend: true,
      },
      contract: {
        operator: '+',
        variables: [
          'mpk_serie',
        ],
        element_wise: true,
      },
      return_id: 'mpk_sum',
    },
    {
      type: 'pps',
      filter: {
        end_time: '$end_time',
        aggregate: '',
        start_time: '$start_time',
        zero_is_nan: false,
      },
      contract: {
        system_id: '$system_id',
        positive_total: false,
      },
      return_id: 'mpk_serie',
    },
    {
      type: `calc`,
      filter: {
        end_time: `$end_time`,
        aggregate: `mean`,
        start_time: `$start_time`,
        offset_end_time: `$offset_end_time`,
        offset_start_time: `$offset_start_time`,
        aggregate_in_frontend: true,
        zero_is_nan: true,
      },
      contract: {
        operator: `/`,
        variables: [`mpk_serie`, `heatload`],
        element_wise: true,
      },
      return_id: `mpk_mean`,
    },
    {
      type: `template`,
      filter: {
        end_time: `$end_time`,
        aggregate: `sum`,
        start_time: `$start_time`,
        aggregate_in_frontend: true,
      },
      contract: {},
      return_id: `ddk`,
    },
    {
      type: 'calc',
      filter: {
        end_time: '$end_time',
        aggregate: 'sum',
        limit_min: 0,
        zero_is_nan: true,
        start_time: '$start_time',
        aggregate_in_frontend: true,
      },
      contract: {
        operator: '+',
        variables: [
          'ddk',
        ],
        element_wise: true,
      },
      return_id: 'ddk_sum',
    },
    {
      type: `template`,
      filter: {
        end_time: `$end_time`,
        aggregate: ``,
        start_time: `$start_time`,
        aggregate_in_frontend: true,
      },
      contract: {},
      return_id: `avk`,
    },    {
      type: 'calc',
      filter: {
        end_time: '$end_time',
        aggregate: 'sum',
        limit_min: 0,
        zero_is_nan: true,
        start_time: '$start_time',
        aggregate_in_frontend: true,
      },
      contract: {
        operator: '+',
        variables: [
          'avk',
        ],
        element_wise: true,
      },
      return_id: 'avk_sum',
    },
    {
      type: `optresults`,
      filter: {
        end_time: `$end_time`,
        aggregate: `sum`,
        start_time: `$start_time`,
        offset_end_time: `$offset_end_time`,
        offset_start_time: `$offset_start_time`,
        aggregate_in_frontend: true,
      },
      contract: {
        sources: [
          {
            source: `$demand_heatload`,
            return_id: `heatload`,
          },
        ],
        opt_time: `$opt_time`,
        system_id: '$system_id',
        opt_job_type_id: 5,
      },
      return_id: ``,
    },
  ],
  alias: {},
}

export const followupForecastChart: UiConfig = {
  display_name: `[chart] Followup forecast`,
  component: `chart`,
  id: -12,
  uid: -12,
  props: {
    items: [
      {
        fill: false,
        unit: ``,
        color: `$color-heatload`,
        order: 1,
        title: `__Heat demand forecast`,
        dashed: true,
        data_id: `heatload_forecast`,
        decimals: 1,
        data_type: `power`,
        y_axis_id: `y`,
      },
      {
        fill: false,
        unit: ``,
        color: `$color-heatload`,
        order: 0,
        title: `__Heat demand outcome `,
        data_id: `heatload_outcome`,
        decimals: 1,
        data_type: `power`,
        y_axis_id: `y`,
      },
      {
        fill: false,
        unit: `°C`,
        color: `$color-temp`,
        order: 2,
        title: `__Temperature forecast`,
        dashed: true,
        data_id: `temp_forecast`,
        decimals: 1,
        data_type: ``,
        y_axis_id: `yTemp`,
      },
      {
        fill: false,
        unit: `°C`,
        color: `$color-temp`,
        order: 3,
        title: `__Temperature outcome `,
        data_id: `temp_outcome`,
        decimals: 1,
        data_type: ``,
        y_axis_id: `yTemp`,
      },
      {
        fill: false,
        unit: ``,
        color: `$color-el`,
        order: 4,
        title: `__Electricity price forecast `,
        dashed: true,
        data_id: `el_price_forecast`,
        decimals: 1,
        data_type: `cost`,
        y_axis_id: `yCost`,
      },
      {
        fill: false,
        unit: ``,
        color: `$color-el`,
        order: 5,
        title: `__Electricity price outcome `,
        data_id: `el_price_outcome`,
        decimals: 1,
        data_type: `cost`,
        y_axis_id: `yCost`,
      },
    ],
    title: ``,
    config: merge(clone(DEFAULT_PROD_PLAN_CONFIG), {
      options: {
        scales: {
          yTemp: {
            grid: {
              display: false,
            },
            title: {
              text: `°C`,
              display: true,
            },
            display: true,
            position: `right`,
          },
          yCost: {
            grid: {
              display: false,
            },
            title: {
              text: '$currency',
              display: true,
            },
            display: true,
            position: 'right',
          },
        },
        plugins: {
          chartLegend: {
            display: true,
            shared: false,
          },
          todayMarker: {
            enabled: true,
          },
        },
      },
    }),
    filter_items: [
      {
        title: `__Demands`,
        return_ids: [`heatload_forecast`, `heatload_outcome`],
      },
      {
        title: `__Temperature`,
        return_ids: [`temp_forecast`, `temp_outcome`],
        default_hidden: true,
      },
      {
        title: `__Electricity prices`,
        return_ids: [`el_price_forecast`, `el_price_outcome`],
        default_hidden: true,
      },
    ],
  },
  dataset_instructions: [
    {
      type: `template`,
      filter: {
        start_time: `$start_time`,
        end_time: `$end_time`,
      },
      return_id: `heatload_forecast`,
    },
    {
      type: `template`,
      filter: {
        start_time: `$start_time`,
        end_time: `$end_time`,
      },
      return_id: `heatload_outcome`,
    },
    {
      type: `template`,
      filter: {
        start_time: `$start_time`,
        end_time: `$end_time`,
      },
      return_id: `temp_forecast`,
    },
    {
      type: `template`,
      filter: {
        start_time: `$start_time`,
        end_time: `$end_time`,
      },
      return_id: `temp_outcome`,
    },
    {
      type: `template`,
      filter: {
        start_time: `$start_time`,
        end_time: `$end_time`,
      },
      return_id: `el_price_forecast`,
    },
    {
      type: `template`,
      filter: {
        start_time: `$start_time`,
        end_time: `$end_time`,
      },
      return_id: `el_price_outcome`,
    },
  ],
  alias: {},
}

export const productionPlanForecastChart: UiConfig = {
  display_name: '[chart] Production forecast',
  component: 'chart',
  id: -14,
  uid: -14,
  props: {
    items: [
      {
        fill: false,
        unit: '',
        color: '$color-heatload',
        order: 1,
        title: '__Heat demand forecast',
        dashed: true,
        data_id: 'heatload_forecast',
        decimals: 1,
        data_type: 'power',
        y_axis_id: 'y',
      },
      {
        fill: false,
        unit: '°C',
        color: '$color-temp',
        order: 2,
        title: '__Temperature forecast',
        dashed: true,
        data_id: 'temp_forecast',
        decimals: 1,
        data_type: '',
        y_axis_id: 'yTemp',
      },
      {
        fill: false,
        unit: '',
        color: '$color-el',
        order: 4,
        title: '__Electricity price forecast ',
        dashed: true,
        data_id: 'el_price',
        decimals: 1,
        data_type: 'cost',
        y_axis_id: 'yCost',
      },
      {
        'fill': false,
        'unit': '',
        'color': '$color-el',
        'order': 4,
        'title': '__Electricity price outcome ',
        'dashed': false,
        'data_id': 'el_price_outcome',
        'decimals': 1,
        'data_type': 'cost',
        'y_axis_id': 'yCost',
      },
    ],
    title: '',
    config: merge(clone(DEFAULT_FORECAST_CONFIG), {
      options: {
        scales: {
          yTemp: {
            grid: {
              display: false,
            },
            title: {
              text: `°C`,
              display: true,
            },
            display: true,
            position: `right`,
          },
          yCost: {
            grid: {
              display: false,
            },
            title: {
              text: '$currency',
              display: true,
            },
            display: true,
            position: 'right',
          },
        },
        plugins: {
          chartLegend: {
            display: true,
            shared: false,
          },
          todayMarker: {
            enabled: true,
          },
        },
      },
    }),
  },
  dataset_instructions: [
    {
      type: 'template',
      filter: {
        start_time: '$start_time',
        end_time: '$end_time',
      },
      return_id: 'heatload_forecast',
    },
    {
      type: 'template',
      filter: {
        start_time: '$start_time',
        end_time: '$end_time',
      },
      return_id: 'temp_forecast',
    },
    {
      'type': 'template',
      'filter': {
        'start_time': '$start_time',
        'end_time': '$end_time',
      },
      'return_id': 'el_price',
    },
    {
      'type': 'template',
      'filter': {
        'start_time': '$start_time',
        'end_time': '$end_time',
      },
      'return_id': 'el_price_outcome',
    },
  ],
  alias: {},
}

export const productionPlanForecastExclElChart: UiConfig = {
  display_name: '[chart] Production forecast (excl. el)',
  component: 'chart',
  id: -15,
  uid: -15,
  props: {
    items: [
      {
        fill: false,
        unit: '',
        color: '$color-heatload',
        order: 1,
        title: '__Heat demand forecast',
        dashed: true,
        data_id: 'heatload_forecast',
        decimals: 1,
        data_type: 'power',
        y_axis_id: 'y',
      },
      {
        fill: false,
        unit: '°C',
        color: '$color-temp',
        order: 2,
        title: '__Temperature forecast',
        dashed: true,
        data_id: 'temp_forecast',
        decimals: 1,
        data_type: '',
        y_axis_id: 'yTemp',
      },
    ],
    title: '',
    config: merge(clone(DEFAULT_PROD_PLAN_CONFIG), {
      options: {
        scales: {
          yTemp: {
            grid: {
              display: false,
            },
            title: {
              text: `°C`,
              display: true,
            },
            display: true,
            position: `right`,
          },
        },
        plugins: {
          chartLegend: {
            display: true,
            shared: false,
          },
          todayMarker: {
            enabled: true,
          },
        },
      },
    }),
  },
  dataset_instructions: [
    {
      type: 'template',
      filter: {
        start_time: '$start_time',
        end_time: '$end_time',
      },
      return_id: 'heatload_forecast',
    },
    {
      type: 'template',
      filter: {
        start_time: '$start_time',
        end_time: '$end_time',
      },
      return_id: 'temp_forecast',
    },
  ],
  alias: {},
}

export const productionPlanChart: UiConfig = {
  display_name: `[chart] Production plan`,
  component: `chart`,
  id: -10,
  uid: -10,
  alias: {
    plan_opt_job_type_id: 2,
    plan_optjob_type: OPTJOB_TYPE_ID_TO_NAME[2],
    meas_opt_job_type_id: 5,
    meas_optjob_type: OPTJOB_TYPE_ID_TO_NAME[5],
    meas_start_time: null,
    meas_end_time: null,
    plan_start_time: null,
    plan_end_time: null,
    start_time: null,
    end_time: null,
    plan_offset_end_time: 168,
    meas_offset_start_time: -168,
  },
  props: {
    items: [],
    base_items: {
      all: true,
    },
    multi_base_item_alias_renaming: [
      {
        opt_job_type_id: `$plan_opt_job_type_id`,
        optjob_type: '$plan_optjob_type',
        start_time: '$plan_start_time',
        end_time: '$plan_end_time',
        offset_end_time: `$plan_offset_end_time`,
      },
      {
        opt_job_type_id: `$meas_opt_job_type_id`,
        optjob_type: '$meas_optjob_type',
        start_time: '$meas_start_time',
        end_time: '$meas_end_time',
        offset_start_time: `$meas_offset_start_time`,
      },
    ],
    config: clone(DEFAULT_PROD_PLAN_CONFIG),
  },
  dataset_instructions: [],
}

export const diffPreviousProductionPlanChart: UiConfig = {
  display_name: `[chart] Production plan difference to previous`,
  component: `chart`,
  id: -24,
  uid: -24,
  alias: {
    digital_twin_id: '$digital_twin_id',
    plan_opt_job_type_id: 2,
    plan_optjob_type: OPTJOB_TYPE_ID_TO_NAME[2],
    offset_start_time: '$offset_start_time',
    offset_end_time: '$offset_end_time',
    start_time: null,
    end_time: null,
    created_at: '$created_at',
  },
  props: {
    items: [],
    operator_for_same_data_id: '-',
    include_items_in_filtering: true,
    include_items_in_grouping: true,
    base_items: {
      all: true,
    },
    multi_base_items_override: [
      {
        item: {config: {
          stepped: 'middle',
        }},
        alias: {
          opt_job_type_id: `$plan_opt_job_type_id`,
          optjob_type: '$plan_optjob_type',
          offset_start_time: '$offset_start_time',
          offset_end_time: '$offset_end_time',
          created_at: '$previous_regular_opt_job_time', // Sent as alias from OptimizeView
          start_time: `$start_time`,
          end_time: `$end_time`,
        },

        // TODO: Remove this when Base Config is migrated to DbS for OptResults
        dataset_instruction: {
          contract: {
            //calculateDatasetsWithSameReturnId takes last alias dataset minus first alias dataset, we needed current minus previous plan
            opt_time: `$previous_regular_opt_job_time`, //sent as alias from OptimizeView
            digital_twin_id: `$digital_twin_id`,
          },
        },
      },
      {
        alias: {
          opt_job_type_id: `$plan_opt_job_type_id`,
          optjob_type: '$plan_optjob_type',
          offset_start_time: '$offset_start_time',
          offset_end_time: '$offset_end_time',
          created_at: null,
          start_time: `$start_time`,
          end_time: `$end_time`,
        },

        // TODO: Remove this when Base Config is migrated to DbS for OptResults
        dataset_instruction: {
          contract: {
            digital_twin_id: `$digital_twin_id`,
          },
        },
      },
    ],
    config: clone(DEFAULT_PROD_PLAN_CONFIG),
  },
  dataset_instructions: [],
}

export const diffBidProductionPlanChart: UiConfig = {
  display_name: `[chart] Production plan difference to bid plan`,
  component: `chart`,
  id: -25,
  uid: -25,
  alias: {
    start_time: null,
    end_time: null,
    created_at: '$created_at',
    plan_opt_job_type_id: 2,
    plan_optjob_type: OPTJOB_TYPE_ID_TO_NAME[2],
    offset_start_time: '$offset_start_time',
    offset_end_time: '$offset_end_time',
    digital_twin_id: '$digital_twin_id',

  },
  props: {
    items: [],
    operator_for_same_data_id: '-',
    include_items_in_filtering: true,
    include_items_in_grouping: true,
    base_items: {
      all: true,
    },
    multi_base_items_override: [
      {
        item: {config: {
          stepped: 'middle',
        }},
        alias: {
          opt_job_type_id: `$plan_opt_job_type_id`,
          optjob_type: '$plan_optjob_type',
          offset_start_time: '$offset_start_time',
          offset_end_time: '$offset_end_time',
          created_at: '$bid_deadline_time', // Created in dynamicDefaultAliases
          start_time: `$start_time`,
          end_time: `$end_time`,
        },

        // TODO: Remove this when Base Config is migrated to DbS for OptResults
        dataset_instruction: {
          contract: {
            //calculateDatasetsWithSameReturnId takes last alias dataset minus first alias dataset, we needed current minus previous plan
            opt_time: `$bid_deadline_time`, //alias created in dynamicDefaultAliases
            digital_twin_id: `$digital_twin_id`,
          },
        },
      },
      {
        alias: {
          opt_job_type_id: `$plan_opt_job_type_id`,
          optjob_type: '$plan_optjob_type',
          offset_start_time: '$offset_start_time',
          offset_end_time: '$offset_end_time',
          created_at: null,
          start_time: `$start_time`,
          end_time: `$end_time`,
        },

        // TODO: Remove this when Base Config is migrated to DbS for OptResults
        dataset_instruction: {
          contract: {
            digital_twin_id: `$digital_twin_id`,
          },
        },
      },
    ],
    config: clone(DEFAULT_PROD_PLAN_CONFIG),
  },
  dataset_instructions: [],
}

export const productionPlanKpi: UiConfig = {
  display_name: `[kpi] Production Plan KPI`,
  component: `kpi_value`,
  id: -9,
  uid: -9,
  props: {
    items: [
      {
        color: `$color-1`,
        title: `__Energy`,
        data_id: `$demand_heatload`,
        tooltip: ``,
        decimals: 2,
        data_type: `energy`,
      },
      {
        color: `$color-1`,
        title: `__Production cost`,
        data_id: `total_cost`,
        tooltip: ``,
        decimals: 2,
        data_type: `cost`,
      },
      {
        color: `$color-1`,
        title: `__Heat production cost`,
        data_id: `production_cost`,
        tooltip: ``,
        decimals: 2,
        data_type: 'cost/energy',
      },
    ],
    style: `value`,
    title: ``,
    position: `left`,
    show_date_range: true,
    has_background_color: false,
    show_zoom_buttons: true,
    show_included_outcome: true,
    applyAggregateOptions: {
      skipInvalidIndices: true,
    },
  },
  dataset_instructions: [
    {
      'type': 'dbs',
      'filter': {
        'aggregate': 'sum',
        'aggregate_in_frontend': true,
        'offset_end_time': '$offset_end_time',
        'offset_start_time': '$offset_start_time',
        'zero_is_nan': false,
        'ignore_dataset_times': true,
        'created_at': '$created_at',
      },
      'contract': {
        'global_read_api': false,
        'id_renaming': {},
        'metadata_filter': {
          'include': {
            'organization': [
              '$organization',
            ],
            'system': [
              '$system',
            ],
            'abs_service': [
              'abs_optservice',
            ],
            'optjob_type': [
              'regular',
            ],
            'internal_id': [
              'total_cost',
              '$demand_heatload',
            ],
          },
          'exclude': {},
        },
      },
      'return_id': '',
    },
    {
      type: `calc`,
      filter: {
        aggregate: `mean`,
        aggregate_in_frontend: true,
        offset_end_time: `$offset_end_time`,
        offset_start_time: `$offset_start_time`,
      },
      contract: {
        operator: `/`,
        variables: [`total_cost`, `$demand_heatload`],
        element_wise: true,
      },
      return_id: `production_cost`,
    },
  ],
  alias: {
    offset_end_time: 168,
    offset_start_time: 0,
    end_time: null,
    start_time: null,

  },
}

export const productionPlanTable: UiConfig = {
  display_name: `[dataset_table] Production Plan Table`,
  component: `dataset_table`,
  id: -11,
  uid: -11,
  props: {
    items: [],
    do_not_show_zero_or_null_values_in_table: true,
    enableColumnActions: false,
    initial_page_size: 25,
    base_items: {
      all: true,
    },
  },
  dataset_instructions: [],
  alias: {
    opt_job_type_id: 2,
    optjob_type: OPTJOB_TYPE_ID_TO_NAME[2],
    offset_end_time: 168,
    offset_start_time: 0,
    start_time: null,
    end_time: null,
  },
}

const productionPlanLightChart = (): UiConfig | null => {
  const uiConfigSnap = snapshot(uiConfigStore)
  const baseUiConfig = uiConfigSnap.baseUiConfig

  if (!baseUiConfig) {
    return null
  }

  const unitNames: Set<string> = new Set()
  const unitNameToOptResultKey: Record<string, string> = {}
  baseUiConfig.dataset_instructions.forEach((datasetInstruciton) => {
    const contract: DatasetContract = (datasetInstruciton.contract as DatasetContractOptResults)
    if (hasSources(contract)) {
      contract?.sources?.forEach(({ source }) => {
        if (source.startsWith(`production`)) {
          const unitName = source.split(`.`).pop()

          if (unitName) {
            unitNames.add(unitName)
            unitNameToOptResultKey[unitName] = source
          }
        }
      })
    }
  })

  const returnIdToItem: Record<string, UiConfigPropsItem> = {}
  baseUiConfig.props?.items?.forEach((item) => {
    returnIdToItem[item.data_id] = item
  })

  const items: UiConfigPropsItem[] = [...Array.from(unitNames)].map((unitName) => ({
    ...returnIdToItem[unitNameToOptResultKey[unitName]],
    data_id: unitName,
    tooltip_do_not_show_value: true,
    config: {
      stepped: `after`,
    },
  }))
  const propertyValuesDatasetInstructions: DatasetInstruction[] = [...Array.from(unitNames)].map((unitName) => ({
    type: `property_values`,
    filter: {
      end_time: null,
      start_time: null,
      offset_end_time: 168,
      offset_start_time: 0,
    },
    contract: {
      object_name: unitName,
      object_type: `unit`,
      opt_model_id: `$opt_model_id`,
      property_name: `max_thermal`,
    },
    return_id: `max_thermal.${unitName}`,
  }))
  const calcDatasetInstructions: DatasetInstruction[] = [...Array.from(unitNames)].map((unitName) => ({
    type: `calc`,
    filter: {
      end_time: null,
      aggregate: ``,
      start_time: null,
      offset_end_time: 168,
      offset_start_time: 0,
    },
    contract: {
      operator: `if`,
      variables: [unitNameToOptResultKey[unitName] + '__lightProdPlan', `max_thermal.${unitName}`],
      element_wise: true,
    },
    return_id: unitName,
  }))

  const base_config_instructions = clone(baseUiConfig.dataset_instructions)
  if (base_config_instructions[0]) {
    base_config_instructions[0].filter = { ...
    base_config_instructions[0].filter, skip_previous_zeros: true, zero_is_nan: true,
    }
    base_config_instructions[0].contract?.sources?.forEach((source) => {
      source.return_id = source.return_id + '__lightProdPlan'
    })
  }

  const config: UiConfig = {
    display_name: `[chart] Production plan (light)`,
    props: {
      items: items,
      config: merge(clone(DEFAULT_PROD_PLAN_CONFIG), {
        options: {
          scales: {
            y: {
              display: false,
            },
            yAcc: {
              display: false,
            },
          },
        },
      }),
      base_items: {
        all: false,
      },
    },
    dataset_instructions: [
      ...base_config_instructions,
      ...propertyValuesDatasetInstructions,
      ...calcDatasetInstructions,
    ],
    alias: {
      opt_job_type_id: 2,
      optjob_type: OPTJOB_TYPE_ID_TO_NAME[2],
      offset_end_time: 168,
      offset_start_time: 0,
      start_time: null,
      end_time: null,
    },
  }

  return config
}

export const productionPlanLightKpi: UiConfig = {
  display_name: `[kpi] Production Plan KPI (light)`,
  component: `kpi_value`,
  id: -9,
  uid: -9,
  props: {
    items: [],
    style: `value`,
    title: ``,
    position: `left`,
    show_date_range: true,
    has_background_color: false,
    show_zoom_buttons: true,
    show_included_outcome: false,
    applyAggregateOptions: {
      skipInvalidIndices: true,
    },
  },
  dataset_instructions: [],
  alias: {
    offset_end_time: 168,
    offset_start_time: 0,
    start_time: null,
    end_time: null  },
}


export const shiftHandoverProdPlan: UiConfig = {
  component: 'shift_handover_chart',
  alias: {},
  props: {
    'use_default': true,
    card: false,
  },
  dataset_instructions: [],
}

export const shiftHandoverProdPlanChart: UiConfig = {
  display_name: '[chart] Shift handover',
  component: `chart`,
  id: -13,
  uid: -13,
  alias: {
    end_time: null,
    start_time: null,
    plan_end_time: `2022-05-22T23:00:00+00:00`,
    meas_start_time: `2022-05-22T07:00:00+00:00`,
    shift_handover_time: `2022-05-22T16:00:00+00:00`,
    meas_opt_job_type_id: 5,
    meas_optjob_type: OPTJOB_TYPE_ID_TO_NAME[5],
    plan_opt_job_type_id: 2,
    plan_optjob_type: OPTJOB_TYPE_ID_TO_NAME[2],
  },
  props: {
    items: [],
    base_items: {
      all: true,
    },
    multi_base_item_alias_renaming: [
      {
        end_time: `$shift_handover_time`,
        start_time: `$meas_start_time`,
        optjob_type: '$meas_optjob_type',
        opt_job_type_id: `$meas_opt_job_type_id`,
      },
      {
        end_time: `$plan_end_time`,
        opt_time: `$shift_handover_time`,
        start_time: `$shift_handover_time`,
        optjob_type: '$plan_optjob_type',
        opt_job_type_id: `$plan_opt_job_type_id`,
      },
    ],
    config: merge(clone(DEFAULT_PROD_PLAN_CONFIG), {
      options: {
        plugins: {
          verticalLine: [
            {
              x: `$shift_handover_time`,
              text: `__⭠ Production outcome - Handover - Optimal plan ⭢`,
            },
          ],
        },
      },
    }),
  },
  dataset_instructions: [],
}

export const marginalCostChart: UiConfig = {
  display_name: '[chart] Marginal cost',
  component: 'marginal_cost_chart',
  props: {
    items: [
      {
        fill: false,
        color: '$color-2',
        order: 0,
        title: '__Submitted marginal cost',
        data_id: 'sent_marginal_cost',
        decimals: 1,
        data_type: 'cost/energy',
        y_axis_id: 'y',
      },
      {
        fill: false,
        color: '$color-2',
        order: 1,
        title: '__Preliminary marginal cost',
        dashed: true,
        data_id: 'prel_marginal_cost',
        decimals: 1,
        data_type: 'cost/energy',
        y_axis_id: 'y',
      },
      {
        fill: true,
        color: '$red',
        order: 3,
        title: '__Declined delivery',
        data_id: 'minus_declined_delivery',
        tooltip_do_not_show_value: true,
        opacity: true,
        decimals: 0,
        data_type: '',
        y_axis_id: 'yRight',
      },
    ],
    title: '',
    config: {
      options: {
        scales: {
          y: {
            title: {
              text: `${getCurrency()}/MWh`,
              display: true,
            },
            grid: yAxisGridSettingsDisplayTrue,
            display: true,
          },
          yRight: {
            max: 0,
            min: -1,
            title: {
              text: '',
              display: false,
            },
            display: false,
          },
          x: { grid: xAxisGridSettings },
        },
        plugins: {
          chartLegend: {
            display: true,
            shared: false,
          },
          todayMarker: {
            enabled: false,
          },
        },
      },
    },
  },
  dataset_instructions: [
    {
      type: 'optresults',
      filter: {
        end_time: '$sent_marginal_cost_end',
        start_time: '$sent_marginal_cost_start',
      },
      contract: {
        sources: [
          {
            source: 'marginal_cost',
            return_id: 'sent_marginal_cost',
          },
        ],
        subtype: null,
        system_id: '$system_id',
        opt_job_type_id: 7,
      },
      return_id: '',
    },    {
      type: 'optresults',
      filter: {
        end_time: '$prel_marginal_cost_end',
        start_time: '$prel_marginal_cost_start',
      },
      contract: {
        sources: [
          {
            source: 'marginal_cost',
            return_id: 'prel_marginal_cost',
          },
        ],
        subtype: null,
        system_id: '$system_id',
        opt_job_type_id: 7,
      },
      return_id: '',
    },
    {
      type: 'dt_property_values',
      filter: {
        end_time: '$prel_marginal_cost_end',
        start_time: '$sent_marginal_cost_start',
        created_at: '$created_at',
      },
      contract: {
        sources: [
          {
            return_id: 'wasteheat_supplier_availability',
            object_name: '$wasteheat_supplier',
            property_name: 'availability',
            setting_level: 'unit',
          },
        ],
        system_id: '$system_id',
        priority_levels: [
          20,
          30,
        ],
        use_default_values: true,
      },
    },
    {
      type: 'calc',
      filter: {
        end_time: '$prel_marginal_cost_end',
        aggregate: '',
        start_time: '$sent_marginal_cost_start',
        zero_is_nan: true,
        offset_end_time: '$offset_end_time',
        offset_start_time: '$offset_start_time',
      },
      contract: {
        operator: '-',
        variables: [
          'wasteheat_supplier_availability',
          1,
        ],
        'element_wise': true,
      },
      return_id: 'minus_declined_delivery',
    },
  ],
  'alias': {
    'sent_marginal_cost_end': null,
    'prel_marginal_cost_end': null,
    'sent_marginal_cost_start': null,
    'prel_marginal_cost_start': null,
  },
}

export const marginalCostKpi: UiConfig = {
  'display_name': '[kpi] Marginal cost',
  'component': 'marginal_cost_kpi',
  'props': {
    'items': [
      {
        'color': '$color-1',
        'title': '__Average cost',
        'data_id': 'marginal_cost_average',
        'decimals': 2,
        'data_type': 'cost/energy',
      },
      {
        'color': '$color-1',
        'title': '__Lowest cost',
        'data_id': 'marginal_cost_min',
        'decimals': 2,
        'data_type': 'cost/energy',
      },
      {
        'color': '$color-1',
        'title': '__Highest cost',
        'data_id': 'marginal_cost_max',
        'decimals': 2,
        'data_type': 'cost/energy',
      },
    ],
    'style': 'value',
    'title': '',
    'position': 'left',
    'paddingLeft': true,
    'show_date_range': false,
    'show_zoom_buttons': false,
    'has_background_color': false,
    'show_included_outcome': false,
  },
  'dataset_instructions': [
    {
      'type': 'dbs',
      'filter': {
        'aggregate': 'mean',
        'offset_end_time': 0,
        'offset_start_time': -24,
      },
      'contract': {
        'global_read_api': false,
        'id_renaming': {
          'marginal_cost': 'marginal_cost_average',
        },
        'metadata_filter': {
          'include': {
            'organization': [
              '$organization',
            ],
            'system': [
              '$system',
            ],
            'abs_service': [
              'abs_optservice',
            ],
            'optjob_type': [
              'marginal_cost_calculation',
            ],
            'internal_id': [
              'marginal_cost',
            ],
          },
          'exclude': {},
        },
      },
      'return_id': '',
    },
    {
      'type': 'dbs',
      'filter': {
        'aggregate': 'min',
        'offset_end_time': 0,
        'offset_start_time': -24,
      },
      'contract': {
        'global_read_api': false,
        'id_renaming': {
          'marginal_cost': 'marginal_cost_min',
        },
        'metadata_filter': {
          'include': {
            'organization': [
              '$organization',
            ],
            'system': [
              '$system',
            ],
            'abs_service': [
              'abs_optservice',
            ],
            'optjob_type': [
              'marginal_cost_calculation',
            ],
            'internal_id': [
              'marginal_cost',
            ],
          },
          'exclude': {},
        },
      },
      'return_id': '',
    },
    {
      'type': 'dbs',
      'filter': {
        'aggregate': 'max',
        'offset_end_time': 0,
        'offset_start_time': -24,
      },
      'contract': {
        'global_read_api': false,
        'id_renaming': {
          'marginal_cost': 'marginal_cost_max',
        },
        'metadata_filter': {
          'include': {
            'organization': [
              '$organization',
            ],
            'system': [
              '$system',
            ],
            'abs_service': [
              'abs_optservice',
            ],
            'optjob_type': [
              'marginal_cost_calculation',
            ],
            'internal_id': [
              'marginal_cost',
            ],
          },
          'exclude': {},
        },
      },
      'return_id': '',
    },
  ],
  'alias': {},
}

export const sandboxProductionKpi: UiConfig = {
  'display_name': '[kpi] Sandbox Production KPI',
  'component': 'kpi_value',
  'props': {
    'items': [
      {
        'color': '$color-1',
        'title': '__Energy',
        'data_id': 'demand',
        'tooltip': '',
        'decimals': 2,
        'data_type': 'energy',
      },
      {
        'color': '$color-1',
        'title': '__Production cost',
        'data_id': 'total_cost',
        'tooltip': '',
        'decimals': 2,
        'data_type': 'cost',
      },
      {
        'data_type': 'cost/energy',
        'color': '$color-1',
        'title': '__Heat production cost',
        'data_id': 'production_cost',
        'tooltip': '',
        'decimals': 2,
      },
    ],
    
    'style': 'value',
    'title': '',
    'position': 'left',
    'show_date_range': false,
    'has_background_color': false,
    'show_zoom_buttons': false,
    'show_included_outcome': false,
    'ignoreZoom': false,
    'applyAggregateOptions': {
      'skipInvalidIndices': true,
    },
  },
  'children_ids': [],
  'dataset_instructions': [
    {
      'type': 'dbs',
      'filter': {
        'aggregate': 'sum',
        'aggregate_in_frontend': true,
        'offset_end_time': '$offset_end_time',
        'offset_start_time': '$offset_start_time',
        'created_at': '$created_at',
      },
      'contract': {
        'global_read_api': false,
        'id_renaming': {
          '$demand_heatload': 'demand',
        },
        'metadata_filter': {
          'include': {
            'organization': [
              '$organization',
            ],
            'system': [
              '$system',
            ],
            'abs_service': [
              'abs_optservice',
            ],
            'optjob_type': [
              '$optjob_type',
            ],
            'internal_id': [
              'total_cost',
              '$demand_heatload',
            ],
          },
          'exclude': {},
        },
      },
      'return_id': '',
    },
    {
      'type': 'calc',
      'filter': {
        'aggregate': 'mean',
        'aggregate_in_frontend': true,
        'offset_end_time': '$offset_end_time',
        'offset_start_time': '$offset_start_time',
        'created_at': '$created_at',
      },
      'contract': {
        'operator': '/',
        'variables': [
          'total_cost',
          'demand',
        ],
        'element_wise': true,
      },
      'return_id': 'production_cost',
    },
  ],
  'alias': {
    'offset_end_time': 0,
    'offset_start_time': 0,
    'start_time': null,
    'end_time': null,
    'opt_job_type_id': 8,
    'optjob_type': OPTJOB_TYPE_ID_TO_NAME[8],
  },
}

export const sandboxProductionPlan: UiConfig = {
  display_name: '[chart] Sandbox production plan',
  component: 'chart',
  uid: -2,
  id: -2,
  props: {
    items: [],
    base_items: {
      all: true,
    },
    config: clone(DEFAULT_PROD_PLAN_CONFIG),
  },
  dataset_instructions: [],
  alias: {
    end_time: null,
    start_time: null,
    offset_end_time: 0,
    offset_start_time: 0,
    opt_job_type_id: 8,
    optjob_type: OPTJOB_TYPE_ID_TO_NAME[8],
  },
}

export const sandboxProductionResult: UiConfig = {
  display_name: '[chart] Sandbox result',
  component: 'sandbox_production_plan',
  alias: {},
  props: {
    'use_default': true,
    card: false,
  },
  dataset_instructions: [],
}

export const sandboxForecast: UiConfig = {
  display_name: '[chart] Sandbox forecast',
  component: 'sandbox_forecast',
  props: {
    items: [
      {
        fill: false,
        unit: '',
        color: '$color-heatload',
        order: 1,
        title: '__Heat demand forecast',
        dashed: true,
        data_id: 'heatload_forecast',
        decimals: 1,
        data_type: 'power',
        y_axis_id: 'y',
      },
      {
        fill: false,
        unit: '°C',
        color: '$color-temp',
        order: 2,
        title: '__Temperature forecast',
        dashed: true,
        data_id: 'temp_forecast',
        decimals: 1,
        data_type: '',
        y_axis_id: 'yTemp',
      },
      {
        fill: false,
        unit: '',
        color: '$color-el',
        order: 4,
        title: '__Electricity price forecast ',
        dashed: true,
        data_id: 'el_price',
        decimals: 1,
        data_type: 'cost',
        y_axis_id: 'yCost',
      },
      {
        'fill': false,
        'unit': '',
        'color': '$color-el',
        'order': 4,
        'title': '__Electricity price outcome ',
        'dashed': false,
        'data_id': 'el_price_outcome',
        'decimals': 1,
        'data_type': 'cost',
        'y_axis_id': 'yCost',
      },
    ],
    title: '',
    config: merge(clone(DEFAULT_FORECAST_CONFIG), {
      options: {
        scales: {
          yTemp: {
            grid: {
              display: false,
            },
            title: {
              text: `°C`,
              display: true,
            },
            display: true,
            position: `right`,
          },
        },
        plugins: {
          chartLegend: {
            display: true,
            shared: false,
          },
          todayMarker: {
            enabled: false,
          },
        },
      },
    }),
  },
  dataset_instructions: [
    {
      type: 'template',
      filter: {
        start_time: '$start_time',
        end_time: '$end_time',
      },
      return_id: 'heatload_forecast',
    },
    {
      type: 'template',
      filter: {
        start_time: '$start_time',
        end_time: '$end_time',
      },
      return_id: 'temp_forecast',
    },
    {
      'type': 'template',
      'filter': {
        'start_time': '$start_time',
        'end_time': '$end_time',
      },
      'return_id': 'el_price',
    },
    {
      'type': 'template',
      'filter': {
        'start_time': '$start_time',
        'end_time': '$end_time',
      },
      'return_id': 'el_price_outcome',
    },
  ],
  alias: {},
}

export const availabilityKpi: UiConfig = {
  display_name: '[kpi] Availability kpi',
  component: 'kpi_value',
  props: {
    items: [
      {
        color: '$availability_percentage',
        title: '__AVAILABILITY',
        data_id: '<unit_name>_availability_percent',
        tooltip: '',
        unit: '%',
        decimals: 2,
        style: 'pie',
        data_type: '',
      },
      {
        title: '__ACCUMULATED LOSS',
        data_id: '<unit_name>_accumulated_loss',
        tooltip: '',
        decimals: 1,
        data_type: 'energy',
      },
    ],
    style: 'value',
    title: '',
    position: 'center',
    show_date_range: false,
    has_background_color: true,
    show_zoom_buttons: false,
    show_included_outcome: false,
    ignoreZoom: false,
    applyAggregateOptions: {
      skipInvalidIndices: true,
    },
  },
  dataset_instructions: [
    {
      type: 'custom/setpoint',
      filter: {
        end_time: '$end_time',
        start_time: '$start_time',
        zero_is_nan: false,
      },
      contract: {
        system_id: '$system_id',
        unit_name: '<unit_name>',
        unit_commodity_name: '<unit_commodity_name>',
        production_sum_units: {
          unit_name: [
            '<unit_name>_commodity_name',
          ],
        },
      },
      return_id: '<unit_name>_setpoint',
    },
    {
      type: 'custom/setpoint_unit_outcome',
      filter: {
        end_time: '$end_time',
        start_time: '$start_time',
        zero_is_nan: false,
      },
      contract: {
        system_id: '$system_id',
        unit_name: '<unit_name>',
        unit_commodity_name: '<unit_commodity_name>',
      },
      return_id: '<unit_name>_setpoint_outcome',
    },
    {
      type: 'calc',
      filter: {
        end_time: '$end_time',
        aggregate: 'sum',
        start_time: '$start_time',
        offset_end_time: '$offset_end_time',
        offset_start_time: '$offset_start_time',
      },
      contract: {
        operator: '-',
        variables: [
          '<unit_name>_setpoint',
          '<unit_name>_setpoint_outcome',
        ],
        element_wise: true,
      },
      return_id: '<unit_name>_accumulated_loss',
    },
    {
      type: 'calc',
      filter: {
        end_time: '$end_time',
        aggregate: 'sum',
        start_time: '$start_time',
        offset_end_time: '$offset_end_time',
        offset_start_time: '$offset_start_time',
        aggregate_in_frontend: true,
      },
      contract: {
        operator: '/',
        variables: [
          '<unit_name>_setpoint_outcome',
          '<unit_name>_setpoint',
        ],
        element_wise: false,
      },
      return_id: '<unit_name>_availability_raw',
    },
    {
      type: 'calc',
      filter: {
        end_time: '$end_time',
        aggregate: '',
        start_time: '$start_time',
        offset_end_time: '$offset_end_time',
        offset_start_time: '$offset_start_time',
      },
      contract: {
        operator: '*',
        variables: [
          '<unit_name>_availability_raw',
          100,
        ],
        element_wise: true,
      },
      return_id: '<unit_name>_availability_percent',
    },
  ],
  alias: {
  },
}

export const availabilityChart: UiConfig = {
  display_name: '[chart] Availability chart',
  props: {
    items: [
      {
        fill: true,
        unit: '',
        color: '$availability_backing',
        order: 0,
        title: '<unit_name> Planerad lastreduktion',
        config: {
          stepped: 'after',
        },
        data_id: '<unit_name>_planned_stop_load_reduction',
        opacity: true,
        decimals: 1,
        data_type: '',
        y_axis_id: 'yRight',
        stack_group: 1,
        tooltip_do_not_show_value: true,
      },
      {
        fill: true,
        unit: '',
        color: '$availability_unavailability',
        order: 1,
        title: '<unit_name> Planerad otillgänglighet',
        config: {
          stepped: 'after',
        },
        data_id: '<unit_name>_planned_stop_unavailability',
        opacity: true,
        decimals: 1,
        data_type: '',
        y_axis_id: 'yRight',
        stack_group: 1,
        tooltip_do_not_show_value: true,
      },
      {
        fill: true,
        unit: '',
        color: '$availability_outcome',
        order: 4,
        title: '<unit_name> Utfall',
        data_id: '<unit_name>_setpoint_outcome',
        decimals: 1,
        data_type: 'power',
        y_axis_id: 'y',
      },
      {
        fill: false,
        unit: '',
        color: '#000',
        order: 2,
        title: '<unit_name> Börvärde',
        data_id: '<unit_name>_setpoint',
        decimals: 1,
        data_type: 'power',
        y_axis_id: 'y',
      },
    ],
    title: '',
    config: {
      options: {
        scales: {
          y: {
            grid: {
              display: true,
              tickWidth: 2,
              borderDash: [
                3,
                5,
              ],
              borderColor: '$grey',
              borderWidth: 2,
            },
            title: {
              text: '',
              display: false,
            },
            display: true,
          },
          yRight: {
            max: 1,
            min: 0,
            grid: {
              display: false,
              tickWidth: 2,
              borderDash: [
                3,
                5,
              ],
              borderColor: '$grey',
              borderWidth: 2,
            },
            title: {
              text: '',
              display: false,
            },
            display: false,
            position: 'right',
          },
        },
        plugins: {
          todayMarker: {
            enabled: false,
          },
          chartLegend: {
            display: true,
            shared: false,
            groupId: '',
          },
        },
      },
    },
    base_items: {
      all: false,
    },
  },
  dataset_instructions: [
    {
      type: 'custom/setpoint',
      filter: {
        end_time: '$end_time',
        start_time: '$start_time',
        zero_is_nan: false,
      },
      contract: {
        system_id: '$system_id',
        unit_name: '<unit_name>',
        unit_commodity_name: '<unit_commodity_name>',
        production_sum_units: {
          unit_name: [
            '<unit_commodity_name>',
          ],
        },
      },
      return_id: '<unit_name>_setpoint',
    },
    {
      type: 'custom/setpoint_unit_outcome',
      filter: {
        end_time: '$end_time',
        start_time: '$start_time',
        zero_is_nan: false,
      },
      contract: {
        system_id: '$system_id',
        unit_name: '<unit_name>',
        unit_commodity_name: '<unit_commodity_name>',
      },
      return_id: '<unit_name>_setpoint_outcome',
    },
    {
      type: 'custom/get_planned_stops_for_unit',
      filter: {
        end_time: '$end_time',
        start_time: '$start_time',
        zero_is_nan: false,
      },
      contract: {
        system_id: '$system_id',
        unit_name: '<unit_name>',
        planned_stop_type: 'planned_backing',
      },
      return_id: '<unit_name>_planned_stop_load_reduction',
    },
    {
      type: 'custom/get_planned_stops_for_unit',
      filter: {
        end_time: '$end_time',
        start_time: '$start_time',
        zero_is_nan: false,
      },
      contract: {
        system_id: '$system_id',
        unit_name: '<unit_name>',
        planned_stop_type: 'planned_unavailability',
      },
      return_id: '<unit_name>_planned_stop_unavailability',
    },
  ],
  'alias': {},
}

export const elplanBids: UiConfig = {
  'children_ids': [],
  'props': {
    'items': [
      {
        'title': 'blockbid.1.volume',
        'data_id': 'blockbid.1.volume',
      },
      {
        'title': 'blockbid.1.price',
        'data_id': 'blockbid.1.price',
      },
      {
        'title': 'blockbid.2.volume',
        'data_id': 'blockbid.2.volume',
      },
      {
        'title': 'blockbid.2.price',
        'data_id': 'blockbid.2.price',
      },
      {
        'title': 'blockbid.3.volume',
        'data_id': 'blockbid.3.volume',
      },
      {
        'title': 'blockbid.3.price',
        'data_id': 'blockbid.3.price',
      },
      {
        'title': 'blockbid.4.volume',
        'data_id': 'blockbid.4.volume',
      },
      {
        'title': 'blockbid.4.price',
        'data_id': 'blockbid.4.price',
      },
      {
        'title': 'blockbid.5.volume',
        'data_id': 'blockbid.5.volume',
      },
      {
        'title': 'blockbid.5.price',
        'data_id': 'blockbid.5.price',
      },
      {
        'title': 'blockbid.6.volume',
        'data_id': 'blockbid.6.volume',
      },
      {
        'title': 'blockbid.6.price',
        'data_id': 'blockbid.6.price',
      },
      {
        'title': 'blockbid.7.volume',
        'data_id': 'blockbid.7.volume',
      },
      {
        'title': 'blockbid.7.price',
        'data_id': 'blockbid.7.price',
      },
      {
        'title': 'blockbid.8.volume',
        'data_id': 'blockbid.8.volume',
      },
      {
        'title': 'blockbid.8.price',
        'data_id': 'blockbid.8.price',
      },
      {
        'title': 'blockbid.9.volume',
        'data_id': 'blockbid.9.volume',
      },
      {
        'title': 'blockbid.9.price',
        'data_id': 'blockbid.9.price',
      },
      {
        'title': 'blockbid.10.volume',
        'data_id': 'blockbid.10.volume',
      },
      {
        'title': 'blockbid.10.price',
        'data_id': 'blockbid.10.price',
      },
      // TODO: Remove blockbids with hardcoded numbers when all uiconfigs are updated
      {
        'title': 'blockbid.*.price',
        'data_id': 'blockbid.*.price',
      },
      {
        'title': 'blockbid.*.volume',
        'data_id': 'blockbid.*.volume',
      },
      {
        'title': 'profilebid.*.price',
        'data_id': 'profilebid.*.price',
      },
      {
        'title': 'profilebid.*.volume',
        'data_id': 'profilebid.*.volume',
      },
      {
        'title': 'spotbid_without_price.volume',
        'data_id': 'spotbid_without_price.volume',
      },
    ],
  },
  'dataset_instructions': [
    {
      'type': 'elplan_bid_management',
      'filter': {
        'end_time': '$start_time',
        'start_time': '$end_time',
        'zero_is_nan': true,
        'offset_end_time': 0,
        'offset_start_time': 0,
      },
      'contract': {
        'sources': [
          {
            'source': 'blockbid.1.volume',
            'return_id': 'blockbid.1.volume',
          },
          {
            'source': 'blockbid.2.volume',
            'return_id': 'blockbid.2.volume',
          },
          {
            'source': 'blockbid.3.volume',
            'return_id': 'blockbid.3.volume',
          },
          {
            'source': 'blockbid.4.volume',
            'return_id': 'blockbid.4.volume',
          },
          {
            'source': 'blockbid.5.volume',
            'return_id': 'blockbid.5.volume',
          },
          {
            'source': 'blockbid.6.volume',
            'return_id': 'blockbid.6.volume',
          },
          {
            'source': 'blockbid.7.volume',
            'return_id': 'blockbid.7.volume',
          },
          {
            'source': 'blockbid.8.volume',
            'return_id': 'blockbid.8.volume',
          },
          {
            'source': 'blockbid.9.volume',
            'return_id': 'blockbid.9.volume',
          },
          {
            'source': 'blockbid.10.volume',
            'return_id': 'blockbid.10.volume',
          },
          {
            'source': 'blockbid.1.price',
            'return_id': 'blockbid.1.price',
          },
          {
            'source': 'blockbid.2.price',
            'return_id': 'blockbid.2.price',
          },
          {
            'source': 'blockbid.3.price',
            'return_id': 'blockbid.3.price',
          },
          {
            'source': 'blockbid.4.price',
            'return_id': 'blockbid.4.price',
          },
          {
            'source': 'blockbid.5.price',
            'return_id': 'blockbid.5.price',
          },
          {
            'source': 'blockbid.6.price',
            'return_id': 'blockbid.6.price',
          },
          {
            'source': 'blockbid.7.price',
            'return_id': 'blockbid.7.price',
          },
          {
            'source': 'blockbid.8.price',
            'return_id': 'blockbid.8.price',
          },
          {
            'source': 'blockbid.9.price',
            'return_id': 'blockbid.9.price',
          },
          {
            'source': 'blockbid.10.price',
            'return_id': 'blockbid.10.price',
          },
          // TODO: Remove blockbids with hardcoded numbers when all ui configs are updated
          {
            'source': 'blockbid.*.price',
            'return_id': 'blockbid.*.price',
          },
          {
            'source': 'blockbid.*.volume',
            'return_id': 'blockbid.*.volume',
          },
          {
            'source': 'profilebid.*.price',
            'return_id': 'profilebid.*.price',
          },
          {
            'source': 'profilebid.*.volume',
            'return_id': 'profilebid.*.volume',
          },
          {
            'source': 'spotbid_without_price.volume',
            'return_id': 'spotbid_without_price.volume',
          },
        ],
        'elplan_info_id': '$elplan_info_id',
      },
      'return_id': 'bids',
    },
  ],
  'alias': {
    'elplan_info_id': 0,
  },
}

async function followupProductionModule(): Promise<void> {
  const authSnap = snapshot(authStore)
  const system = authSnap.systemId

  const totalEnery = clone(followupProdTotalEnergyHorizontalBars)
  const totalCost = clone(followupProdTotalCostHorizontalBars)
  const optimalChart = clone(followupProdOptimalChart)
  const outcomeIncDevChart = clone(followupInclusiveDeviationsOutcomeChart)
  const outcomeExclDevChart = clone(followupExclusiveDeviationsOutcomeChart)
  const outcomeProdPlanChart = clone(followupProdPlanOutcomeChart)

  await createUiConfig({
    component: `followup_production`,
    system: system,
    children_ids: [
      totalEnery.id,
      totalCost.id,
      optimalChart.id,
      outcomeIncDevChart.id,
      outcomeExclDevChart.id,
      outcomeProdPlanChart.id,
    ],
    alias: {},
    dataset_instructions: [],
    props: {
      headerTabs: [
        {
          title: ``,
          kpiId: null,
          tabs: [
            {
              title: `__Optimal production`,
              kpi: {
                ui_config_id: NaN,
                override_alias: {},
              },
              total_cost: {
                ui_config_id: totalCost.id,
                override_alias: {
                  optimal_opt_job_type_id: 4,
                  optimal_optjob_type: OPTJOB_TYPE_ID_TO_NAME[4],
                  outcome_opt_job_type_id: 5,
                  outcome_optjob_type: OPTJOB_TYPE_TO_ID_NAME[5],
                },
              },
              total_production: {
                ui_config_id: totalEnery.id,
                override_alias: {
                  optimal_opt_job_type_id: 4,
                  optimal_optjob_type: OPTJOB_TYPE_ID_TO_NAME[4],
                  outcome_opt_job_type_id: 5,
                  outcome_optjob_type: OPTJOB_TYPE_TO_ID_NAME[5],
                },
              },
              optimal: {
                ui_config_id: optimalChart.id,
                override_alias: { opt_job_type_id: 4, optjob_type: OPTJOB_TYPE_ID_TO_NAME[4] },
              },
              outcome: {
                ui_config_id: outcomeExclDevChart.id,
                override_alias: {},
              },
            },
            {
              title: `__Include deviations`,
              kpi: {
                ui_config_id: NaN,
                override_alias: {},
              },
              total_cost: {
                ui_config_id: totalCost.id,
                override_alias: {
                  optimal_opt_job_type_id: 3,
                  optimal_optjob_type: OPTJOB_TYPE_ID_TO_NAME[3],
                  outcome_opt_job_type_id: 5,
                  outcome_optjob_type: OPTJOB_TYPE_TO_ID_NAME[5],
                },
              },
              total_production: {
                ui_config_id: totalEnery.id,
                override_alias: {
                  optimal_opt_job_type_id: 3,
                  optimal_optjob_type: OPTJOB_TYPE_ID_TO_NAME[3],
                  outcome_opt_job_type_id: 5,
                  outcome_optjob_type: OPTJOB_TYPE_TO_ID_NAME[5],
                },
              },
              optimal: {
                ui_config_id: optimalChart.id,
                override_alias: { opt_job_type_id: 3, optjob_type: OPTJOB_TYPE_ID_TO_NAME[3] },
              },
              outcome: {
                ui_config_id: outcomeIncDevChart.id,
                override_alias: {},
              },
            },
            {
              title: `__Production plan`,
              kpi: {
                ui_config_id: NaN,
                override_alias: {},
              },
              total_cost: {
                ui_config_id: totalCost.id,
                override_alias: {
                  optimal_opt_job_type_id: 2,
                  optimal_optjob_type: OPTJOB_TYPE_ID_TO_NAME[2],
                  outcome_opt_job_type_id: 5,
                  outcome_optjob_type: OPTJOB_TYPE_TO_ID_NAME[5],
                },
              },
              total_production: {
                ui_config_id: totalEnery.id,
                override_alias: {
                  optimal_opt_job_type_id: 2,
                  optimal_optjob_type: OPTJOB_TYPE_ID_TO_NAME[2],
                  outcome_opt_job_type_id: 5,
                  outcome_optjob_type: OPTJOB_TYPE_TO_ID_NAME[5],
                },
              },
              optimal: {
                ui_config_id: optimalChart.id,
                override_alias: { opt_job_type_id: 2, optjob_type: OPTJOB_TYPE_ID_TO_NAME[2] },
              },
              outcome: {
                ui_config_id: outcomeProdPlanChart.id,
                override_alias: {},
              },
            },
          ],
        },
      ],
    },
  })

  await createUiConfig({
    ...clone(uiConfigWithDefaults),
    system,
    component: 'follow_up_prod_kpi',
  })

  window.location.reload()
}

async function followupProductionExclRatedPowerModule(): Promise<void> {
  const authSnap = snapshot(authStore)
  const system = authSnap.systemId

  await createUiConfig({
    component: `followup_production`,
    system: system,
    children_ids: [
      followupProdTotalEnergyHorizontalBars.id,
      followupProdTotalCostHorizontalBars.id,
      followupProdOptimalExclRatedPowerChart.id,
      followupInclusiveDeviationsOutcomeExklRatedPowerChart.id,
      followupExclusiveDeviationsOutcomeExclRatedPowerChart.id,
      followupProdPlanOutcomeExclRatedPowerChart.id,
    ],
    alias: {},
    dataset_instructions: [],
    props: {
      headerTabs: [
        {
          title: ``,
          kpiId: null,
          tabs: [
            {
              title: `__Optimal production`,
              kpi: {
                ui_config_id: NaN,
                override_alias: {},
              },
              total_cost: {
                ui_config_id: followupProdTotalCostHorizontalBars.id,
                override_alias: {
                  optimal_opt_job_type_id: 4,
                  optimal_optjob_type: OPTJOB_TYPE_ID_TO_NAME[4],
                  outcome_opt_job_type_id: 5,
                  outcome_optjob_type: OPTJOB_TYPE_ID_TO_NAME[5],
                },
              },
              total_production: {
                ui_config_id: followupProdTotalEnergyHorizontalBars.id,
                override_alias: {
                  optimal_opt_job_type_id: 4,
                  optimal_optjob_type: OPTJOB_TYPE_ID_TO_NAME[4],
                  outcome_opt_job_type_id: 5,
                  outcome_optjob_type: OPTJOB_TYPE_ID_TO_NAME[5],
                },
              },
              optimal: {
                ui_config_id: followupProdOptimalExclRatedPowerChart.id,
                override_alias: { opt_job_type_id: 4, optjob_type: OPTJOB_TYPE_ID_TO_NAME[4] },
              },
              outcome: {
                ui_config_id: followupExclusiveDeviationsOutcomeExclRatedPowerChart.id,
                override_alias: {},
              },
            },
            {
              title: `__Include deviations`,
              kpi: {
                ui_config_id: NaN,
                override_alias: {},
              },
              total_cost: {
                ui_config_id: followupProdTotalCostHorizontalBars.id,
                override_alias: {
                  optimal_opt_job_type_id: 3,
                  optimal_optjob_type: OPTJOB_TYPE_ID_TO_NAME[3],
                  outcome_opt_job_type_id: 5,
                  outcome_optjob_type: OPTJOB_TYPE_ID_TO_NAME[5],
                },
              },
              total_production: {
                ui_config_id: followupProdTotalEnergyHorizontalBars.id,
                override_alias: {
                  optimal_opt_job_type_id: 3,
                  optimal_optjob_type: OPTJOB_TYPE_ID_TO_NAME[3],
                  outcome_opt_job_type_id: 5,
                  outcome_optjob_type: OPTJOB_TYPE_ID_TO_NAME[5],
                },
              },
              optimal: {
                ui_config_id: followupProdOptimalExclRatedPowerChart.id,
                override_alias: { opt_job_type_id: 3, optjob_type: OPTJOB_TYPE_ID_TO_NAME[3] },
              },
              outcome: {
                ui_config_id: followupInclusiveDeviationsOutcomeExklRatedPowerChart.id,
                override_alias: {},
              },
            },
            {
              title: `__Production plan`,
              kpi: {
                ui_config_id: NaN,
                override_alias: {},
              },
              total_cost: {
                ui_config_id: followupProdTotalCostHorizontalBars.id,
                override_alias: {
                  optimal_opt_job_type_id: 2,
                  optimal_optjob_type: OPTJOB_TYPE_ID_TO_NAME[2],
                  outcome_opt_job_type_id: 5,
                  outcome_optjob_type: OPTJOB_TYPE_ID_TO_NAME[5],
                },
              },
              total_production: {
                ui_config_id: followupProdTotalEnergyHorizontalBars.id,
                override_alias: {
                  optimal_opt_job_type_id: 2,
                  optimal_optjob_type: OPTJOB_TYPE_ID_TO_NAME[2],
                  outcome_opt_job_type_id: 5,
                  outcome_optjob_type: OPTJOB_TYPE_ID_TO_NAME[5],
                },
              },
              optimal: {
                ui_config_id: followupProdOptimalExclRatedPowerChart.id,
                override_alias: { opt_job_type_id: 2, optjob_type: OPTJOB_TYPE_ID_TO_NAME[2] },
              },
              outcome: {
                ui_config_id: followupProdPlanOutcomeExclRatedPowerChart.id,
                override_alias: {},
              },
            },
          ],
        },
      ],
    },
  })

  await createUiConfig({
    ...clone(uiConfigWithDefaults),
    system,
    component: 'follow_up_prod_kpi',
  })

  window.location.reload()
}

async function sandboxModule(): Promise<void> {
  const authSnap = snapshot(authStore)
  const system = authSnap.systemId

  await createUiConfig({
    ...clone(uiConfigWithDefaults),
    system,
    component: 'sandbox_forecast',
  })

  const sandboxResult = {
    ...clone(uiConfigWithDefaults),
    system,
    component: 'sandbox_production_plan',
  }
  sandboxResult.props = {
    ...sandboxResult.props,
    card: false,
  }

  await createUiConfig(sandboxResult)
  window.location.reload()
}

async function optimizePlan(): Promise<void> {
  const authSnap = snapshot(authStore)
  const system = authSnap.systemId

  await createUiConfig({
    ...clone(uiConfigWithDefaults),
    system,
    component: 'optimize_production_plan',
  })

  await createUiConfig({
    ...clone(uiConfigWithDefaults),
    system,
    component: 'production_plan_forecast',
  })

  await createUiConfig({
    ...clone(uiConfigWithDefaults),
    system,
    component: 'production_plan_table',
  })

  window.location.reload()
}

async function shiftHandover(): Promise<void> {
  const authSnap = snapshot(authStore)
  const system = authSnap.systemId

  await createUiConfig({
    ...clone(uiConfigWithDefaults),
    system,
    component: 'shift_handover_chart',
  })

  window.location.reload()
}

async function followupForecast(): Promise<void> {
  const authSnap = snapshot(authStore)
  const system = authSnap.systemId

  await createUiConfig({
    ...clone(uiConfigWithDefaults),
    system,
    component: 'followup_forecast',
  })

  window.location.reload()
}

function genericUseDefaultGenerator(componentName: string) {
  return async () => {
    const authSnap = snapshot(authStore)
    const system = authSnap.systemId

    await createUiConfig({
      ...clone(uiConfigWithDefaults),
      system,
      component: componentName,
    })

    window.location.reload()
  }
}

async function samenergi(): Promise<void> {
  const authSnap = snapshot(authStore)
  const system = authSnap.systemId

  await createUiConfig({
    ...clone(uiConfigWithDefaults),
    system,
    component: 'marginal_cost_kpi',
  })

  await createUiConfig({
    ...clone(uiConfigWithDefaults),
    system,
    component: 'marginal_cost_chart',
  })

  window.location.reload()
}

export const modules: { module: string, altTitle?: string, generator: () => void }[] = [{
  module: 'optimize_production_plan',
  generator: optimizePlan,
}, {
  module: 'followup_production',
  generator: followupProductionModule,
}, {
  module: 'followup_production',
  altTitle: 'followup_production (excl. rated power)',
  generator: followupProductionExclRatedPowerModule,
}, {
  module: 'followup_forecast',
  generator: followupForecast,
}, {
  module: 'review_forecasts',
  altTitle: i18n.t('Review forecasts'),
  generator: genericUseDefaultGenerator('review_forecasts'),
}, {
  module: 'sandbox_production_plan',
  generator: sandboxModule,
}, {
  module: 'shift_handover_chart',
  generator: shiftHandover,
}, {
  module: 'samenergi_overview',
  generator: samenergi,
}]

export { reviewForecasts, reviewForecastChart, reviewAccuracyKpi }

export default [
  // Optimize module
  productionPlanKpi,
  productionPlanChart,
  productionPlanLightChart,
  productionPlanLightKpi,
  productionPlanForecastChart,
  productionPlanTable,

  // Followup production
  followupProductionKpi,
  followupProdOptimalChart,
  followupInclusiveDeviationsOutcomeChart,
  followupProdPlanOutcomeChart,
  followupExclusiveDeviationsOutcomeChart,
  followupProdTotalEnergyHorizontalBars,
  followupProdTotalCostHorizontalBars,

  // Followup forecast
  followupForecastChart,
  reviewForecasts,

  //Review forecast
  reviewForecastChart,
  reviewAccuracyKpi,

  // Shift handover
  shiftHandoverProdPlanChart,

  // Sandbox
  sandboxForecast,
  sandboxProductionKpi,
  sandboxProductionPlan,

  // Marginal cost
  marginalCostChart,
  marginalCostKpi,

  //Availability
  availabilityKpi,
  availabilityChart,

] as UiConfig[]
