import React, { ReactElement, useState } from 'react'

import { ListItem, ListItemIcon, Icon, Typography, Collapse, List, Grid, Tooltip } from '@mui/material'
import classNames from 'classnames'
import { Location } from 'history'
import { SubMenu } from 'ui/components/Navigation/types'

import SidebarItem from '../SidebarItem/SidebarItem'

import styles from './SubMenuItem.module.less'

type SubMenuItemProps = {
  item: SubMenu
  location: Location
  sideBarOpen: boolean
}

export default function SubMenuItem({ item, location, sideBarOpen }: SubMenuItemProps): ReactElement {
  const [expanded, setExpanded] = useState(false)
  return (
    <>
      <li>
        <ListItem
          button
          classes={{
            root: classNames(styles.SubMenuItem_ListItem, {
              [styles.SubMenuItem_ActiveNested]: item.children.find((child) => {
                return child.path === location.pathname
              }),
            }),
          }}
          onClick={() => setExpanded(!expanded)}
        >
          <Tooltip arrow placement="right" title={sideBarOpen ? `` : item.sidebarName}>
            <Grid container justifyContent="space-between" alignItems="flex-end" direction="row">
              <Grid item container alignItems="flex-start" xs={10}>
                <Grid item>
                  <ListItemIcon classes={{ root: styles.SubMenuItem_Text }}>
                    {typeof item.icon === `string` ? <Icon className={item.icon} /> : <item.icon />}
                  </ListItemIcon>
                </Grid>
                {sideBarOpen ? (
                  <Grid item>
                    <Typography className={styles.SubMenuItem_Text} variant="h5">
                      {item.sidebarName}
                    </Typography>
                  </Grid>
                ) : null}
              </Grid>
              <Grid item xs={2}>
                <Icon
                  style={{ color: `white` }}
                  fontSize="inherit"
                  className={classNames(styles.SubMenuItem_SubMenuItem, `fal fa-chevron-right`, {
                    [styles.SubMenuItem_SubMenuIconOpen]: expanded,
                  })}
                />
              </Grid>
            </Grid>
          </Tooltip>
        </ListItem>
      </li>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {item.children.map((module, i) => {
            return (
              <SidebarItem
                item={module}
                key={item.sidebarName + i}
                active={module.path === location.pathname}
                sideBarOpen={sideBarOpen}
                nested
              />
            )
          })}
        </List>
      </Collapse>
    </>
  )
}
