export type ApiRequestStatus = 'loading' | 'error' | 'success' | 'idle'

export function combinedStatus(statuses: ApiRequestStatus[]): ApiRequestStatus {
  if (statuses.includes('error')) {
    return 'error'
  }

  if (statuses.includes('loading')) {
    return 'loading'
  }

  if (statuses.every((status) => status === 'success')) {
    return 'success'
  }

  return 'idle'
}

export function clearLocalStorage() {
  // Clear the local storage, but keep the language
  const language = localStorage.getItem('language')
  localStorage.clear()
  if (language) {
    localStorage.setItem('language', language)
  }
}
