import React, { ReactElement } from 'react'

import { ListItem, ListItemIcon, Icon, Typography, Tooltip } from '@mui/material'
import classNames from 'classnames'
import { Route } from 'ui/components/Navigation/types'
import StyledLink from 'ui/components/StyledLink/StyledLink'

import styles from './SidebarItem.module.less'


type SidebarItemProps = {
  item: Route
  active: boolean
  nested?: boolean
  sideBarOpen: boolean
}

function SidebarItem({ item, active, nested, sideBarOpen }: SidebarItemProps): ReactElement {
  const lang = localStorage.getItem('language')
  return (
    <Tooltip arrow placement="right" title={sideBarOpen ? `` : item.sidebarName ?? ``}>
      <li lang={lang ?? 'en'}>
        <StyledLink to={item.path.split(`:`, 1)[0]}>
          <ListItem
            button
            key={item.sidebarName}
            className={classNames(styles.SidebarItem_ListItem, {
              [styles.SidebarItem_ActiveListItem]: active,
              [styles.SidebarItem_Nested]: nested,
              [styles.SidebarItem_ActiveNested]: nested && active,
            })}
          >
            {item.icon ? (
              <ListItemIcon className={styles.SidebarItem_Text}>
                {typeof item.icon === `string` ? (
                  <Icon
                    className={classNames(item.icon, {
                      [styles.SidebarItem_NestedText]: nested,
                    })}
                  />
                ) : (
                  <item.icon />
                )}
              </ListItemIcon>
            ) : null}
            {sideBarOpen && (
              <Typography className={classNames(styles.SidebarItem_Text, { [styles.SidebarItem_NestedText]: nested })} variant="h5">
                {nested && item.sidebarNameAsChild ? item.sidebarNameAsChild : item.sidebarName ?? item.sidebarName}
              </Typography>
            )}
          </ListItem>
        </StyledLink>
      </li>
    </Tooltip>
  )
}

export default React.memo(SidebarItem, (prev, next) => {
  return prev.active === next.active && prev.sideBarOpen === next.sideBarOpen
})
