import React, { ReactElement } from 'react'

import { Switch, Redirect } from 'react-router-dom'
import { RoutesArray } from 'ui/components/Navigation/types'

import { PrivateRoute } from './components/PrivateRoute/PrivateRoute'

type PrivateSwitchProps = {
  routes: RoutesArray
}

export function PrivateSwitch({ routes }: PrivateSwitchProps): ReactElement {
  const filteredRoutes = routes.flatMap((route) => (route.children ? route.children : route))

  return (
    <Switch>
      {filteredRoutes.map((route, key) => {
        if (route.redirect && route.to) return <Redirect from={route.path} to={route.to} key={key} />
        return (
          <PrivateRoute
            path={route.path}
            component={route.component}
            title={route.navbarName ? route.navbarName : undefined}
            key={key}
            contentComponent={route.contentComponent}
          />
        )
      })}
    </Switch>
  )
}

export default PrivateSwitch
