import React, { ReactElement, ReactNode } from 'react'

import { ClickAwayListener, InputAdornment, TextField as MuiTextField } from '@mui/material'

type TextFieldProps = {
  value?: string | number
  unit?: string | ReactElement
  label?: string
  name?: string
  error?: boolean
  fullWidth?: boolean
  autoFocus?: boolean
  variant?: `outlined` | `standard` | `filled`
  type?: `text` | `number`
  onChange?: (value: string | number, e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onEnter?: (value?: string) => void
  onEscape?: (value?: string) => void
  onClickAway?: (value?: string) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  'data-testid'?: string
  disabled?: boolean
  required?: boolean
  margin?: 'none' | 'dense' | 'normal'
  unitPosition?: 'start' | 'end'
  helperText?: string | undefined
}

export default function TextField({
  value = ``,
  onChange,
  onEnter,
  onEscape,
  onClickAway,
  onBlur,
  label = ``,
  name,
  unit,
  error = false,
  fullWidth = true,
  variant = `outlined`,
  type = `text`,
  autoFocus = false,
  'data-testid': dataTestId,
  disabled = false,
  required = false,
  margin = 'none',
  unitPosition = 'end',
  helperText,
  ...props
}: TextFieldProps): ReactElement {
  let inputProps: Record<string, ReactNode> | undefined = undefined

  if (unit) {
    if (!inputProps) {
      inputProps = {}
    }

    if (unitPosition === 'start') {
      inputProps.startAdornment = <InputAdornment position="start">{unit}</InputAdornment>
    } else {
      inputProps.endAdornment = <InputAdornment position="end">{unit}</InputAdornment>
    }
  }

  return (
    <ClickAwayListener onClickAway={() => onClickAway?.()}>
      <MuiTextField
        inputProps={{ 'data-testid': dataTestId }}
        autoFocus={autoFocus}
        fullWidth={fullWidth}
        error={!!error}
        aria-label={label}
        label={label}
        name={name}
        variant={variant}
        value={value}
        type={type}
        margin={margin}
        InputProps={inputProps}
        helperText={helperText}
        onChange={(e) => onChange(e.target.value, e)}
        onKeyUp={(e) => {
          const value: string = e.target?.value ?? ''

          if (onEnter && e?.key === 'Enter') {
            onEnter(value)
          } else if (onEscape && e?.key === 'Escape') {
            onEscape(value)
          }
        }}
        onBlur={(e) => {
          if (onBlur) {
            onBlur(e)
          }
        }}
        disabled={disabled}
        required={required}
        {...props}
      />
    </ClickAwayListener>
  )
}
