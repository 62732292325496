export type PrimaryColors = {
  main: React.CSSProperties[`color`]
  hover: React.CSSProperties[`color`]
  disabled: React.CSSProperties[`color`]
  contrastText: React.CSSProperties[`color`]
}

const primary = {
  main: `#006c80`,
  hover: `#004f5e`,
  disabled: `#5ea9b7`,
  contrastText: `#fff`,
}

export default primary
