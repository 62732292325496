import React from 'react'

import { Dialog, Popover as ReactAriaPopover } from 'react-aria-components'

import styles from './Popover.module.less'

interface Props {
  children: React.ReactNode;
}

export function Popover(props: Props): JSX.Element {
  return (
    <ReactAriaPopover className={styles.Popover}>
      <Dialog className={styles.Dialog}>
        {props.children}
      </Dialog>
    </ReactAriaPopover>
  )
}