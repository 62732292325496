// The first priority in each array is the default for new settings.
export const BASE_SETTING_PRIO = {
  priority_level: 10,
  display_name: 'Base setting',
}

export const BASE_SETTING_PRIORITIES = [BASE_SETTING_PRIO]

export const PLANNED_DEVIATION_SETTING_PRIO = {
  priority_level: 20,
  display_name: 'Planned',
}

export const UNPLANNED_DEVIATION_SETTING_PRIO = {
  priority_level: 30,
  display_name: 'Unplanned',
}

export const DEVIATION_SETTING_PRIORITIES = [UNPLANNED_DEVIATION_SETTING_PRIO, PLANNED_DEVIATION_SETTING_PRIO]
