import auth, { ACTIVE_SYSTEM_LOCAL_STORAGE_KEY } from 'store/auth/auth'
import { proxy, snapshot } from 'valtio'
import { subscribeKey } from 'valtio/utils'

type GlobalStore = {
  navigationIsOpen: boolean
  notificationCenterIsOpen: boolean
  windowWidth: number
  windowHeight: number
  systemShortcut: boolean
  bodyWidth: number
}

const NAVBAR_WIDTH = 240
const NAVBAR_WIDTH_NARROW = 73
const RESIZE_BOUNCE_DELAY = 400
const ANIMATION_DURATION = 200

let resizeStartTime = 0
let isResizing = false

window.onresize = () => {
  resizeStartTime = +new Date()

  if (isResizing) {
    return
  }

  isResizing = true
  setTimeout(resizeEnded, RESIZE_BOUNCE_DELAY)
}

function resizeEnded(): void {
  if (+new Date() - resizeStartTime < RESIZE_BOUNCE_DELAY) {
    setTimeout(resizeEnded, RESIZE_BOUNCE_DELAY)
  } else {
    isResizing = false
    globalStore.windowWidth = window.innerWidth
    globalStore.windowHeight = window.innerHeight
  }
}

const globalStore = proxy<GlobalStore>(
  {
    navigationIsOpen: localStorage.getItem(`navigationIsOpen`) !== `false`,
    notificationCenterIsOpen: false,
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    systemShortcut: localStorage.getItem('systemShortcut') === 'true',
    get bodyWidth() {
      return this.navigationIsOpen ? this.windowWidth - NAVBAR_WIDTH : this.windowWidth - NAVBAR_WIDTH_NARROW
    },
  }
)

subscribeKey(globalStore, `navigationIsOpen`, (navigationIsOpen) => {
  localStorage.setItem(`navigationIsOpen`, navigationIsOpen ? `true` : `false`)
  setTimeout(() => {
    // Force update to trigger after animations are complete
    globalStore.windowWidth = window.innerWidth - 1
    setTimeout(resizeEnded, 0)
  }, ANIMATION_DURATION)
})

subscribeKey(globalStore, 'systemShortcut', (systemShortcut) => {
  localStorage.setItem('systemShortcut', systemShortcut ? 'true' : 'false')
})

window.onbeforeunload = () => {
  const authSnap = snapshot(auth)
  localStorage.setItem(ACTIVE_SYSTEM_LOCAL_STORAGE_KEY, JSON.stringify(authSnap.activeSystem))
}

export default globalStore
