import React, { ReactElement, PropsWithChildren } from 'react'

import { Icon, Grid, MenuItem } from '@mui/material'
import classNames from 'classnames'

import styles from './ProfileMenuItem.module.less'

type ProfileMenuItemProps = {
  icon: string
  onClick?: () => void
}

export default function ProfileMenuItem({
  icon,
  children,
  onClick,
}: PropsWithChildren<ProfileMenuItemProps>): ReactElement {
  return (
    <MenuItem onClick={onClick}>
      <Grid container direction="row" alignItems="center">
        <Grid item>
          <Icon fontSize="small" className={classNames(styles.ProfileMenuItem_Icon, icon)} />
        </Grid>
        <Grid item>{children}</Grid>
      </Grid>
    </MenuItem>
  )
}
