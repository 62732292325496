import React, { useContext } from 'react'

import { CalendarDate, parseDate } from '@internationalized/date'
import { Icon } from '@mui/material'
import classNames from 'classnames'
import moment, { Moment } from 'moment'
import { Button, DatePicker, DatePickerStateContext, Group } from 'react-aria-components'
import { useTranslation } from 'react-i18next'

import { Calendar } from '../internal/Calendar/Calendar'
import { Popover } from '../internal/Calendar/Popover'

import styles from './ArrowDatePicker.module.less'
import { StepButton } from './StepButton'

type CalendarProps = React.ComponentProps<typeof Calendar>

// Same as subract
type MomentAddProps = Parameters<Moment['add']>
type StepUnit = MomentAddProps[0]
type StepAmount = MomentAddProps[1]
type Step = {
  unit: StepUnit
  amount: StepAmount
}

interface Props {
  theme?: 'light' | 'dark'
  
  prevButtonTooltip?: string
  nextButtonTooltip?: string
  format: string

  value: Moment
  minValue?: Moment
  maxValue?: Moment

  onPrev?: () => void
  onNext?: () => void

  onChange?: (date: Moment) => void

  step?: Step

  // Using `week` will visually select the whole week containg `value`.
  visualSelection?: CalendarProps['visualSelection']

  showWeekNumbers?: boolean

  ariaLabel?: string
}

const CALENDAR_DATE_FORMAT = 'YYYY-MM-DD'

function toCalendarDate(date: Moment): CalendarDate {
  return parseDate(date.format(CALENDAR_DATE_FORMAT))
}

function toMoment(date: CalendarDate): Moment {
  return moment(date.toString())
}

export function ArrowDatePicker(props: Props): JSX.Element {
  const { t } = useTranslation()
  const ariaLabel = props.ariaLabel || t('Date picker')

  const minValue = props.minValue ? toCalendarDate(props.minValue) : undefined
  const maxValue = props.maxValue ? toCalendarDate(props.maxValue) : undefined
  const visualSelection = props.visualSelection || 'day'

  const prevButtonTooltip = props.prevButtonTooltip ?? t('Previous')
  const nextButtonTooltip = props.nextButtonTooltip ?? t('Next')

  const step = props.step || { unit: 'day', amount: 1 }

  return (
    <DatePicker
      className={props.theme === 'dark' ? styles.Dark : styles.Light}
      aria-label={ariaLabel}
      value={toCalendarDate(props.value)}
      minValue={minValue}
      maxValue={maxValue}
      onChange={(date) => {
        props.onChange && props.onChange(toMoment(date))
      }}
    >
      <Group className={styles.Group}>
        <PrevButton
          minValue={minValue}
          onClick={() => {
            props.onPrev && props.onPrev()
          }}
          step={step}
          tooltip={prevButtonTooltip}
        />
        <Button className={styles.Button} data-testid="toggle-calendar-btn">
          <Icon className={classNames(`far fa-calendar-alt`, styles.StartAdornment)} />
          <FormattedDate format={props.format} />
        </Button>
        <NextButton
          onClick={() => {
            props.onNext && props.onNext()
          }}
          step={step}
          tooltip={nextButtonTooltip}
          maxValue={maxValue}
        />
      </Group>
      <Popover>
        <Calendar visualSelection={visualSelection} showWeekNumbers={props.showWeekNumbers} />
      </Popover>
    </DatePicker>
  )
}

interface PrevButtonProps {
  onClick: () => void
  tooltip: string
  minValue?: CalendarDate
  step: Step
}
function PrevButton(props: PrevButtonProps): JSX.Element {
  const { minValue } = props
  const datepicker = useContext(DatePickerStateContext)
  const selectedDate = datepicker.value as CalendarDate

  const isDisabled = minValue && selectedDate ? selectedDate.compare(minValue) <= 0 : false

  return (
    <StepButton
      isDisabled={isDisabled}
      onClick={() => {
        if (selectedDate) {
          const selectedDateAsMoment = toMoment(selectedDate)
          const newDate = selectedDateAsMoment.subtract(props.step.amount, props.step.unit)
          datepicker.setValue(toCalendarDate(newDate))
        }

        props.onClick()
      }}
      tooltip={props.tooltip}
      testId='prev-step'
      icon={<Icon className="fal fa-chevron-left" fontSize="small" />}
    />
  )
}

interface NextButtonProps {
  onClick: () => void
  tooltip: string
  maxValue?: CalendarDate
  step: Step
}
function NextButton(props: NextButtonProps): JSX.Element {
  const { maxValue } = props
  const datepicker = useContext(DatePickerStateContext)
  const selectedDate = datepicker.value as CalendarDate
  const isDisabled = maxValue && datepicker.value ? datepicker.value.compare(maxValue) >= 0 : false

  return (
    <StepButton
      isDisabled={isDisabled}
      onClick={() => {
        if (selectedDate) {
          const selectedDateAsMoment = toMoment(selectedDate)
          const newDate = selectedDateAsMoment.add(props.step.amount, props.step.unit)
          datepicker.setValue(toCalendarDate(newDate))
        }

        props.onClick()
      }}
      tooltip={props.tooltip}
      testId='next-step'
      icon={<Icon className="fal fa-chevron-right" fontSize="small" />}
    />
  )
}

interface FormattedDateProps {
  format: string
}

function FormattedDate(props: FormattedDateProps): JSX.Element | null {
  const datepicker = useContext(DatePickerStateContext)
  const date = datepicker.value

  if (!date) {
    // TODO: return some placeholder... "Select a date"
    return null
  }

  const formattedDate = moment(date.toString()).format(props.format)

  return <>{formattedDate}</>
}
