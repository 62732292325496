import { clearLocalStorage } from 'api/api.utils'
import { apiClient } from 'api/apiClient/apiClient'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { setMFAAuth } from 'store/mfa/mfa'
import { useAlert } from 'ui/components/AlertContext/AlertContext'
import { useAuth } from 'ui/components/AuthContext/AuthContext'

export type LogInResponse = {
  key: string
  user: User
}

type AccountActivationData = { email: string; token: string; passwd: string; confirm_passwd: string }

export function useAccountActivation() {
  return useMutation((data: AccountActivationData) => apiClient(`users/activate_account`, { data }))
}
export function useLoginMutation() {
  const { t } = useTranslation()
  const { error: errorAlert } = useAlert()
  const { setUser } = useAuth()
  return useMutation(
    ({ email, password }: { email: string; password: string }) =>
      apiClient<LogInResponse>(`auth/login`, {
        data: {
          email: email.toLowerCase(),
          password: password,
        },
      }),
    {
      onError: () => {
        errorAlert(t(`Could not login. Please check your credentials and try again`))
      },
      onSuccess: (response) => {
        if (response.key) {
          const token = response.key
          const user: User = { ...response.user, token }
          setUser(user)
        }
        else if (!response.user_has_active_mfa) {
          setMFAAuth(true, false, response.ephemeral_token)
        } else if (response.user_has_active_mfa && response.method === 'sms_api') {
          setMFAAuth(true, true, response.ephemeral_token)
        }
      },
    }
  )
}

export function useLogoutMutation() {
  return useMutation(
    () => apiClient(`auth/logout`, { method: `POST` }),
    {
      onSuccess: () => {
        clearLocalStorage()
        document.title = `Aurora by Sigholm`
        window.location.replace(`/`)
      },
    })
}
export function useChangePasswordMutation() {
  const { t } = useTranslation()
  const { error: errorAlert, success } = useAlert()

  return useMutation(
    ({
      newPassword1,
      newPassword2,
      currentPassword,
    }: {
      newPassword1: string
      newPassword2: string
      currentPassword: string
    }) =>
      apiClient(`auth/password/change`, {
        data: {
          new_password1: newPassword1,
          new_password2: newPassword2,
          old_password: currentPassword,
        },
      }),
    {
      onError: () => {
        errorAlert(t(`We could not update your password due to current password do not match, please try again`))
      },
      onSuccess: () => {
        success(t(`The password has been updated`))
      },
    }
  )
}

export function useResetPasswordMutation() {
  const { t } = useTranslation()
  const { success } = useAlert()
  return useMutation(
    ({ email, captchaValue }: { email: string; captchaValue: string }) =>
      apiClient(`auth/password/reset`, {
        data: {
          email: email.toLowerCase(),
          captcha_value: captchaValue,
        },
      }),
    {
      onSuccess: () => {
        success(
          t(`We have recieved your request to reset your password. An email with further instructions is now sent!`)
        )
      },
    }
  )
}
export function useConfirmResetPasswordMutation() {
  const { t } = useTranslation()
  const { error: errorAlert, success } = useAlert()
  return useMutation(
    ({
      newPassword1,
      newPassword2,
      uid,
      token,
    }: {
      newPassword1: string
      newPassword2: string
      uid: string
      token: string
    }) =>
      apiClient(`auth/password/reset/confirm`, {
        data: {
          new_password1: newPassword1,
          new_password2: newPassword2,
          uid: uid,
          token: token,
        },
      }),
    {
      onError: () => {
        errorAlert(
          t(
            `Your password could not be changed. Please check that the password meet all the criteras and that your reset link is still valid.`
          )
        )
      },
      onSuccess: () => {
        success(t(`Your password is now reset. You may now log in with your new password!`))
        window.location.replace(`/`)
      },
    }
  )
}
