export function dynamicClassName(
  optionalClassNames: Record<string, string | boolean>,
  classNames?: string | string[]
): string {
  let activeClassNames = ``

  if (typeof classNames === `string`) {
    activeClassNames = `${activeClassNames} ${classNames}`
  } else if (Array.isArray(classNames)) {
    activeClassNames = `${activeClassNames} ${classNames.join(` `)}`
  }

  return Object.entries(optionalClassNames).reduce((acc, [key, value]) => {
    if (value) {
      return `${acc} ${key}`
    }
    return acc
  }, activeClassNames)
}
