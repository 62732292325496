import React, { ReactElement } from 'react'

import { CheckCircle, Warning, Error, Info, Close } from '@mui/icons-material'
import { Snackbar, SnackbarContent, IconButton } from '@mui/material'
import { SnackbarProps } from '@mui/material/Snackbar'
import classNames from 'classnames'

import styles from './Alert.module.less'

export type AlertVariant = `success` | `error` | `warning` | `info`

const variantIcon = {
  success: CheckCircle,
  warning: Warning,
  error: Error,
  info: Info,
}

type AlertProps = {
  className?: string
  variant: AlertVariant
  message: string
  onClose?: () => void
  open: boolean
  SnackbarProps?: Partial<SnackbarProps>
}

function Alert({ className, variant, message, onClose, open, SnackbarProps, ...rest }: AlertProps): ReactElement {
  const Icon = variantIcon[variant]

  let dynamicIcon

  if (variant === 'success') {
    dynamicIcon = styles.Alert_Success
  } else if (variant === 'error') {
    dynamicIcon = styles.Alert_Error
  } else if (variant === 'warning') {
    dynamicIcon = styles.Alert_Warning
  } else if (variant === 'info') {
    dynamicIcon = styles.Alert_Info
  }

  return message !== `` ? (
    <Snackbar
      anchorOrigin={{
        vertical: `top`,
        horizontal: `center`,
      }}
      open={open}
      autoHideDuration={30000}
      onClose={onClose}
      {...SnackbarProps}
    >
      <SnackbarContent
        className={classNames(dynamicIcon, className, styles.Alert_Wrapper)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={styles.Alert_Message}>
            <Icon className={classNames(styles.Alert_Icon, styles.Alert_IconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose} size="large">
            <Close className={styles.Alert_Icon} />
          </IconButton>,
        ]}
        {...rest}
      />
    </Snackbar>
  ) : (
    <></>
  )
}

export default Alert
