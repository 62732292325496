/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement } from 'react'

const componentNames: Set<string> = new Set()
const uiConfigComponents: Record<string, (props: any) => ReactElement> = {}
const uiConfigComponentsAsOtherUiConfigComponents: Record<string, string> = {}
const appendPropsWithDatasetsRegistry: Record<
  string,
  (datasetInstruction: DatasetInstruction, props: any) => Record<string, any>
> = {}
const defaultProps: Record<string, any> = {}
const singletonComponents: Set<string> = new Set()
const componentTitles: Record<string, string> = {}
const componentHeights: Record<string, string | number> = {}

export function registerUiConfigComponent(
  componentName: string,
  component: (props: any) => ReactElement,
  options?: {
    isSingletonComponent?: boolean
    title?: string
  }
): void {
  uiConfigComponents[componentName] = component
  componentNames.add(componentName)

  if (options?.isSingletonComponent) {
    singletonComponents.add(componentName)
  }

  if (options?.title) {
    componentTitles[componentName] = options.title
  }

}

export function registerUiConfigComponentAsOtherComponent(
  componentName: string,
  otherComponentName: string,
  options?: {
    isSingletonComponent?: boolean
    title?: string
    height?: string | number
  }
): void {
  uiConfigComponentsAsOtherUiConfigComponents[componentName] = otherComponentName
  componentNames.add(componentName)

  if (options?.isSingletonComponent) {
    singletonComponents.add(componentName)
  }

  if (options?.title) {
    componentTitles[componentName] = options.title
  }

  if (options?.height) {
    registerComponentHeight(componentName, options.height)
  }
}

export function registerAppendPropsWithDatasets<T>(
  componentName: string,
  callback: (datasetInstruction: DatasetInstruction, previousProps: T) => Record<string, any>
): void {
  appendPropsWithDatasetsRegistry[componentName] = callback
}

export function registerDefaultProps(componentName: string, props: Record<string, any>): void {
  defaultProps[componentName] = props
}

export function registerComponentHeight(componentName: string, height: string | number): void {
  componentHeights[componentName] = height
}

export function getComponentHeight(componentName: string): string | number | undefined {
  return componentHeights[componentName]
}

export function getAllComponentNames(): string[] {
  return Array.from(componentNames)
}

export function getAllSingletonComponentNames(): string[] {
  return Array.from(singletonComponents)
}

export function getComponentTitle(componentName: string, returnEmptyIfMissing = false): string {
  if (returnEmptyIfMissing && !componentTitles[componentName]) {
    return ''
  }

  return componentTitles[componentName] || componentName
}

export function getDefaultProps(componentName: string): Record<string, any> {
  if (uiConfigComponentsAsOtherUiConfigComponents[componentName]) {
    componentName = uiConfigComponentsAsOtherUiConfigComponents[componentName]
  }

  return defaultProps[componentName] ?? {}
}

export function appendPropsWithDataset(
  componentName: string,
  datasetInstruction: DatasetInstruction,
  previousProps: Record<string, any>
): Record<string, any> {
  return appendPropsWithDatasetsRegistry[componentName]?.(datasetInstruction, previousProps) ?? previousProps
}

export default function getUiConfigComponent(componentName: string): (props: any) => ReactElement {
  if (uiConfigComponentsAsOtherUiConfigComponents[componentName]) {
    componentName = uiConfigComponentsAsOtherUiConfigComponents[componentName]
  }

  return uiConfigComponents[componentName]
}
