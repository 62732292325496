import React, { useState, useRef, ReactElement, useEffect } from 'react'

import { Button, Popper, Paper, ClickAwayListener, Icon, MenuList } from '@mui/material'
import { postNavigateToStat } from 'api/stats/stats.api'
import { useSystems } from 'api/systems/systems.api'
import classNames from 'classnames'
import authStore from 'store/auth/auth'
import globalStore from 'store/global/global'
import { TextField } from 'ui/atoms'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import { useSnapshot } from 'valtio'

import { getDisplayName } from 'helpers/global.helper/global.helper'

import styles from './SystemMenu.module.less'


export default function SystemMenu(): ReactElement {
  const { data: systems } = useSystems()
  const { setActiveSystem, activeSystem } = useAuth()
  const [open, setOpen] = useState(false)
  const [filterText, setFilterText] = useState('')
  const globalSnap = useSnapshot(globalStore)
  const anchorEl = useRef<null | HTMLButtonElement>(null)
  const filteredSystems = filterText ? (systems || []).filter(system => getDisplayName(system).toLowerCase().indexOf(filterText) !== -1) : (systems || [])

  useEffect(() => {
    if (globalSnap.systemShortcut) {
      window.addEventListener('keyup', (e) => {
        if (e.target === document.body && e.key === 's') {
          setOpen(true)
        }
      })
    }
  }, [globalSnap.systemShortcut])

  function handleClose(event?: React.MouseEvent<HTMLLIElement> | React.MouseEvent<Document>): void {
    if (event && anchorEl?.current?.contains(event.currentTarget)) {
      return
    }

    setOpen(false)
    setFilterText('')
  }

  function selectSystem(system?: System): void {
    if (system) {
      //if System changes while user is currently located within a sandbox project, we need to redirect to the sandbox overview before changing the system
      //since projects are tied to currently chosen System so uiconfigs and properties will not be available for the newly chosen System
      if(location.pathname.match(/sandbox\/([0-9]+)/g)){
        window.location.replace(`/sandbox/`)
      }
      const old_token = activeSystem?.custom_translation_token

      setActiveSystem(system)
      authStore.activeSystem = system
      postNavigateToStat()

      if (old_token !== system.custom_translation_token) {
        setTimeout(() => {
          location.reload()
        }, 500)
      }
    }

    handleClose()
  }

  return (
    <>
      <Button
        color="inherit"
        ref={anchorEl}
        aria-owns={open ? `simple-menu` : undefined}
        aria-haspopup="true"
        aria-label={activeSystem ? getDisplayName(activeSystem) : `System menu`}
        onClick={() => {setOpen(!open)}}
        data-testid="system-menu"
      >
        <Icon className={classNames(styles.SystemMenu_Button_LeftIcon, `fal fa-industry`)} />
        <div className={styles.SystemMenu_Button}>{activeSystem ? getDisplayName(activeSystem) : ``}</div>
        <Icon
          className={classNames(styles.SystemMenu_Button_RightIcon, `fal fa-angle-down animation`, {
            [styles.SystemMenu_Button_RightIcon__open]: open,
          })}
        />
      </Button>

      <Popper open={open} anchorEl={anchorEl.current} disablePortal>
        <ClickAwayListener onClickAway={handleClose}>
          <Paper>
            <MenuList className={styles.SystemMenu_List}>
              <TextField
                autoFocus={true}
                value={filterText}
                onChange={(value, e) => {
                  setFilterText(value.toLowerCase())
                  e?.preventDefault()
                  e?.stopPropagation()
                }}
                onEnter={() => {
                  selectSystem(filteredSystems[0])
                }}
                onEscape={() => {
                  handleClose()
                }}
                data-testid="system-menu-search-field"
              />
              {filteredSystems
                .sort((a, b) => a.display_name.localeCompare(b.display_name))
                .map((system: System) => (
                  <div className={styles.SystemMenu_Item} key={system.id} onClick={() => selectSystem(system)}>
                    {getDisplayName(system)}
                  </div>
                ))}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  )
}
