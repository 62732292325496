export type TextExtraColors = {
  one: React.CSSProperties[`color`]
  two: React.CSSProperties[`color`]
  three: React.CSSProperties[`color`]
  four: React.CSSProperties[`color`]
}

const textExtra = {
  one: `#152029`,
  two: `#455057`,
  three: `#657178`,
  four: `#fff`,
}

export default textExtra
