import { BidType, ElplanInfo, ELPLAN_BID_REFRESH_EVENT_QUERY_KEY, PlanValues } from 'api/elplan/elplan.api'
import Datetime from 'utils/datetime/datetime'
import { proxy } from 'valtio'
import { subscribeKey } from 'valtio/utils'

import { queryClient } from 'helpers/queryClient'

export type ElplanValues = {
  id: number,
  elplan: number,
  volume: number,
  start_time: ISODateTime,
  end_time: ISODateTime,
  updated_at: ISODateTime,
  excelExportFooterText: string
}
type ElplanStore = ElplanInfo & {
  elplan: {
    elplan_id: number,
    is_locked: boolean,
    has_plan: boolean,
    values: ElplanValues[],
    date: ISODateTime | string,
    day_ahead_supplier_results?: ElplanValues[],
  },
  hasPassedDeadline: boolean,
  spotBidWithoutPriceInfo: { amount: number | undefined, minimum_hours_for_bid: number | undefined }
  blockBidInfo: {amount: number | undefined, minimum_hours_for_bid: number | undefined}
  brokerDeadlineTime: `${number}:${number}:${number}`
  only_positive_bids?: boolean
}

const elplanStore = proxy<ElplanStore>(
  {
    elplan_info_id: 0,
    deadline_time: '',
    broker_name: '',
    excel_file_name: '',
    currency: '',
    bid_types: [],
    excelExportFooterText: '',
    integration: '',
    maxBidVolume: null,
    only_positive_bids: false,
    date: {
      start_time: '',
      end_time: '',
    },
    elplan: {
      elplan_id: 0,
      is_locked: false,
      has_plan: false,
      values: [],
      date: '',
      day_ahead_supplier_results: [],
    },
    get hasPassedDeadline () {
      if(this.deadline_time){
        const now = Datetime.getISONow()
        const deadlineTime = new Date()
        const [hours, minutes, seconds] = this.deadline_time.split(':').map(Number)
        deadlineTime.setHours(hours, minutes, seconds, 0)
        if (Datetime.isAfter(now, deadlineTime.toISOString() as ISODateTime)) {
          return true
        }
      }

      return false
    },
    get brokerDeadlineTime() {
      return this.deadline_time ? this.deadline_time : '11:30:00'
    },
    get spotBidWithoutPriceInfo () {
      const spotBidInfo = this.bid_types.find((type) => type.type === 'spotbid_without_price')

      return {
        amount: spotBidInfo?.amount,
        minimum_hours_for_bid: spotBidInfo?.minimum_hours_for_bid,
      }
    },
    get blockBidInfo () {
      const blockBidInfo = this.bid_types.find((type) => type.type === 'blockbid')

      return {
        amount: blockBidInfo?.amount,
        minimum_hours_for_bid: blockBidInfo?.minimum_hours_for_bid,
      }
    },
    get profileBidInfo () {
      const profileBidInfo = this.bid_types.find((type) => type.type === 'profilebid')

      return {
        amount: profileBidInfo?.amount,
        minimum_hours_for_bid: profileBidInfo?.minimum_hours_for_bid,
      }
    },
  }
)

subscribeKey(elplanStore, `id`, (elplanInfoId) => {
  elplanStore.id = elplanInfoId ?? 0
  queryClient.invalidateQueries(ELPLAN_BID_REFRESH_EVENT_QUERY_KEY)
})

export function setElplanInfoStore(
  deadline_time: `${number}:${number}:${number}`,
  broker_name: string,
  currency: string,
  bid_types: BidType[],
  excel_file_name: string,
  id: number,
  excelExportFooterText?: string,
  integration?: string | undefined | null,
  maxBidVolume?: number | undefined | null,
  only_positive_bids?: boolean
): void {

  elplanStore.deadline_time = deadline_time
  elplanStore.broker_name = broker_name
  elplanStore.currency = currency
  elplanStore.bid_types = bid_types
  elplanStore.excel_file_name = excel_file_name
  elplanStore.elplan_info_id = id
  elplanStore.excelExportFooterText = excelExportFooterText
  elplanStore.integration = integration
  elplanStore.maxBidVolume = maxBidVolume
  elplanStore.only_positive_bids = only_positive_bids
}

export function setCurrentDate(startTime: ISODateTime, endTime: ISODateTime): void{
  elplanStore.date.start_time = startTime
  elplanStore.date.end_time = endTime
}

export function setElplan(id: number, isLocked: boolean, hasPlan: boolean, values: PlanValues[], date: ISODateTime, day_ahead_supplier_results?: PlanValues[]): void{
  elplanStore.elplan.elplan_id = id
  elplanStore.elplan.is_locked = isLocked
  elplanStore.elplan.has_plan = hasPlan
  elplanStore.elplan.values = values
  elplanStore.elplan.date = date
  elplanStore.elplan.date = date
  elplanStore.elplan.day_ahead_supplier_results = day_ahead_supplier_results

}

export default elplanStore