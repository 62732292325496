import { grey, red } from '@mui/material/colors'
import { ThemeOptions, createTheme } from '@mui/material/styles'

import common from './colors/common'
import primary from './colors/primary'
import secondary from './colors/secondary'
import support from './colors/support'
import textExtra from './colors/text'
import ui from './colors/ui'
import MuiButtonStyles from './styles/MuiButton.styles'

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    grey: true;
  }
}

declare module '@mui/material' {
  interface Color {
    main: string;
    dark: string;
  }
}

const palette: ThemeOptions['palette'] = {
  common: { black: common.black, white: common.white },
  grey: {
    main: grey[300],
    dark: grey[400],
  },
  background: { paper: common.white, default: ui.two },
  moduleHeader: {
    backgroundColor: common.white,
  },
  widget: {
    header: {
      backgroundColor: ui.seven,
      color: common.black,
    },
  },
  menus: {
    sidebar: {
      listItemActive: primary.main,
      listItemHover: primary.hover,
      main: ui.five,
      nestedListItem: primary.hover,
      text: ui.one,
      nestedListItemActive: ui.six,
    },
    topbar: {
      main: ui.five,
      text: ui.one,
    },
  },
  table: {
    rowHighlight: {
      editing: `#FAFAA7`,
      deleted: `#FF8471`,
      added: `#8EE8A1`,
    },
  },
  ui: ui,
  primary: primary,
  secondary: secondary,
  textExtra: textExtra,
  support: support,
  error: red,
  contrastThreshold: 3,
  tonalOffset: 0.2,
}

const baseTheme = createTheme({
  palette,
})

export const themeProps: ThemeOptions = {
  mixins: {
    drawer: {
      width: 240,
    },
    toolbar: {
      minHeight: 48,
    },
    widget: {
      header: {
        height: 40,
      },
    },
  },
  palette,
  typography: {
    fontFamily: `"Open Sans", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    h1: {
      fontFamily: `"Raleway", "Open Sans", "Helvetica", "Arial", sans-serif`,
      fontSize: 24,
      fontWeight: 800,
    },
    h2: {
      fontFamily: `"Raleway", "Open Sans", "Helvetica", "Arial", sans-serif`,
      fontSize: 16,
      fontWeight: 500,
    },
    h3: {
      fontFamily: `"Raleway", "Open Sans", "Helvetica", "Arial", sans-serif`,
      fontSize: 14,
      fontWeight: 500,
    },
    h4: {
      fontFamily: `"Raleway", "Open Sans", "Helvetica", "Arial", sans-serif`,
      fontSize: 14,
      fontWeight: 500,
    },
    h5: {
      fontFamily: `"Raleway", "Open Sans", "Helvetica", "Arial", sans-serif`,
      fontSize: 16,
      fontWeight: 500,
    },
    h6: {
      fontFamily: `"Raleway", "Open Sans", "Helvetica", "Arial", sans-serif`,
      fontSize: 16,
      fontWeight: 500,
    },

    button: {
      textTransform: 'none',
    },
    body1: { fontSize: 14 },
  },
  components: {
    MuiButton: {
      styleOverrides: MuiButtonStyles,
      variants: [
        {
          props: { color: 'grey', variant: 'text' },
          style: {
            color: baseTheme.palette.text.primary,
          },
        },
      ],
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          overflow: `visible`,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: `none`,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: `10px`,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          height: 10,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          height: 10,
          minHeight: 0,
        },
      },
    },
  },
}

const theme = createTheme(themeProps)

export default theme
