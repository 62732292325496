import Datetime from 'utils/datetime/datetime'
import { z } from 'zod'

const ISODateTimeSchema = z.custom<ISODateTime>((value) => {
  try {
    Datetime.toISOString(value)
    return true
  } catch (e) {
    return false
  }
})

const stringToObjectShema = z.string().transform((value) => {
  try {
    return JSON.parse(value)
  } catch (e) {
    return value
  }
})

const ZoomResolutionSchema = z.union([
  z.object({ type: z.literal(`day`) }),
  z.object({ type: z.literal(`week`) }),
])

const ZoomResolutionSchemaWithCustom = z.union([
  ZoomResolutionSchema,
  z.object({
    type: z.literal('custom'),
    startTime: ISODateTimeSchema,
    endTime: ISODateTimeSchema,
  }),
])

export type ZoomResolution = z.infer<typeof ZoomResolutionSchema> | z.infer<typeof ZoomResolutionSchemaWithCustom>

type Options = { allowCustom: boolean }

export function parseZoomResolution(maybeStringifiedSchema: string | null, fallback: ZoomResolution, opts?: Options): ZoomResolution {
  const allowCustom = opts?.allowCustom ?? false
  const schema = allowCustom ? ZoomResolutionSchemaWithCustom : ZoomResolutionSchema

  const res = stringToObjectShema.pipe(schema).safeParse(maybeStringifiedSchema)

  if (res.success) {
    return res.data
  }

  return fallback
}