export type SupportColors = {
  error: React.CSSProperties[`color`]
  errorHover: React.CSSProperties[`color`]
  success: React.CSSProperties[`color`]
  successHover: React.CSSProperties[`color`]
  warning: React.CSSProperties[`color`]
  warningHover: React.CSSProperties[`color`]
  neutral: React.CSSProperties[`color`]
  neutralHover: React.CSSProperties[`color`]
}

const support: SupportColors = {
  error: `#e0182d`,
  errorHover: `#b9172e`,
  success: `#5aa832`,
  successHover: `#4c8e2a`,
  warning: `#f1c202`,
  warningHover: `#caa506`,
  neutral: `#3381c4`,
  neutralHover: `#3C6C96`,
}

export default support
